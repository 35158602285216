import React, { useRef, useState } from "react";  // Add useState
import "./ChatMessages.css";

const ChatMessages = ({ chatData = [], closeChat, onSendMessage }) => {  // Add onSendMessage prop
  const inputRef = useRef(null);
  const [messageText, setMessageText] = useState('');  // Add state for message text

  const handleIconClick = () => {
    inputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (messageText.trim()) {
      onSendMessage(messageText);
      setMessageText(''); // Clear input after sending
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="title">Call messages</div>
        <div className="close-icon cursor-pointer" onClick={() => closeChat()}>
          <img src="assets/images/chat-close-icon.png" alt="" />
        </div>
      </div>

      <div className="chat-messages">
        {chatData.map((message, index) => (
          <div
            key={index}
            className={`chat-bubble ${
              message.sender.includes("you") ? "user-bubble" : "manager-bubble"
            }`}
          >
            {message.text && (
              <div
                className="msg"
                dangerouslySetInnerHTML={{ __html: message?.text }}
              />
            )}
            <div className="chat-info">
              <img src={message.avatar} alt="avatar" className="avatar" />
              <span className="sender-name">{message.sender}</span>
            </div>
            {message.attachments.map((file, i) => (
              <div className="attachment" key={i}>
                <a
                  href={file.link}
                  download
                  className="attachment-link cursor-pointer"
                >
                  <img src="/assets/images/document-pdf.png" alt="" />{" "}
                  {file.name}
                </a>
                <a href={file.link} className="download-icon">
                  <img src="/assets/images/download-pdf.png" alt="" />
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="chat-input">
        <div className="input-box-wrap">
          <input 
            type="text" 
            placeholder="Type message..." 
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <div className="attach-file cursor-pointer" onClick={handleIconClick}>
            <input type="file" style={{ display: "none" }} ref={inputRef} />
            <img src="/assets/images/attatchment-icon.png" alt="" />
          </div>
        </div>

        <button type="submit" className="send-btn">
          <img src="/assets/images/send-icon.png" alt="" />
        </button>
      </form>
    </div>
  );
};

export default ChatMessages;

import React, { Fragment, useState, useEffect } from "react";
import ValidationErrorToast from "../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";

export default function Signup(props) {
  let store = props?.props?.history?.location?.state?.userType;
  const [userType, setUserType] = useState(store ? store : "")

  const signupNavigator = () => {
    if (userType === STRING_CONSTANTS.userType.employer) {
      props.props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 1, userType: STRING_CONSTANTS.userType.employer, isGuest: props?.props?.location?.state?.isGuest, account_exists: props?.props?.location?.state?.account_exists })
    }
    else if (userType === STRING_CONSTANTS.userType.employee) {
      props.props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 1, userType: STRING_CONSTANTS.userType.employee, isGuest: props?.props?.location?.state?.isGuest, account_exists: props?.props?.location?.state?.account_exists })
    }
    else ValidationErrorToast("Please select your role", "")
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const signupType = queryParams.get('type');
    
    if (signupType === 'accounts') {
      setUserType(STRING_CONSTANTS.userType.employer);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('isAccountsSignup', 'true');
      }
      // Navigate using the same pattern as signupNavigator
      props.props.history.push(STRING_CONSTANTS.navigationString.signup, { 
        step: 1, 
        userType: STRING_CONSTANTS.userType.employer, 
        isGuest: props?.props?.location?.state?.isGuest, 
        account_exists: props?.props?.location?.state?.account_exists,
        isAccountsSignup: true
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem('isAccountsSignup');
      }
    }
  }, []);

  const styles = {
    radioButtonCard: {
      transition: 'all 0.3s ease',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      cursor: 'pointer',
      padding: '60px 30px',
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      minHeight: '240px',
      width: '280px',
      margin: '0 20px'  // Increased margin between cards
    },
    radioButtonCardActive: {
      backgroundColor: 'rgba(6, 239, 197, 0.1)',  // The exact preview background color
      border: '1px solid #06EFC5',                // The exact preview border color
      color: '#06EFC5'                           // The exact preview text color
    },
    cardText: {
      color: 'rgba(255, 255, 255, 0.7)',
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center'
    },
    cardTextActive: {
      color: '#06EFC5'  // Making the text match the preview color when active
    },
    cardIcon: {
      width: '48px',
      height: '48px',
      opacity: 0.7
    },
    cardIconActive: {
      opacity: 1,
      filter: 'invert(83%) sepia(93%) saturate(1265%) hue-rotate(116deg) brightness(101%) contrast(97%)'  // This will tint the icon to match #06EFC5
    },
    backButton: {
      backgroundColor: 'rgba(6, 239, 197, 0.1)',
      border: '1px solid #06EFC5',
      color: '#06EFC5',
      padding: '8px 16px',
      borderRadius: '8px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px'
    },
    accountButton: {
      backgroundColor: 'rgba(6, 239, 197, 0.1)',
      border: '1px solid #06EFC5',
      color: '#06EFC5',
      padding: '8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'all 0.3s ease',
      height: '35px',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };

  const onBoarding = {
    pathname: "/",
    state: { activeIndex: 2 }
  }
  return <>
    <div className="row">
      <div className="col-md-3 col-lg-4 col-xl-4 col-12">
        <div className="left-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              onClick={() => props.props.history.push(onBoarding)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 col-12">
        <div className="middle-wrap">
          <div className='top-divde-spa'>
            <span>
              <i
                className="fa fa-angle-left text-white d-flex justify-content-between"
                aria-hidden="true"
                onClick={() => {
                  if (props?.props?.location?.state?.isGuest) {
                    props?.props?.history?.push(STRING_CONSTANTS.navigationString.dashboard.home, { isGuest: true })
                  }
                  else if (props?.props?.location?.state?.account_exists) {
                    props?.props?.history?.push(STRING_CONSTANTS.navigationString.index)
                  }
                  else props?.props?.history?.goBack()
                }
                }
              ></i>
            </span>
            <div className="step-content signup-mar">
              <button type='button' disabled>{STRING_CONSTANTS.signupPages.signup.sign_up}</button>
              <p>Are you looking to hire or<br /> looking to work?</p>
              <div className="step-text">
                Step 1/6
              </div>
            </div>
          </div>
          <div className="form-wrap">
  <div className='custom-grid-setup' style={{
    display: 'flex',
    justifyContent: 'center',  // Center the cards
    alignItems: 'center',
    gap: '40px',              // Explicit gap between cards
    maxWidth: '800px',        // Limit the maximum width
    margin: '0 auto',         // Center the container
    padding: '20px'           // Add some padding
  }}>
    <div className='radio-button-card-set' style={{ flex: 1 }}>
      <input
        type="radio" 
        name="select_job" 
        id="business_owner" 
      />
      <label 
        htmlFor="business_owner" 
        onClick={() => setUserType(STRING_CONSTANTS.userType.employer)}
        style={{
          ...styles.radioButtonCard,
          ...(userType === STRING_CONSTANTS.userType.employer ? styles.radioButtonCardActive : {})
        }}
      >
        <img 
          src={UtilityFunctions.getImageURL() + (userType === STRING_CONSTANTS.userType.employer ? 
            "person-icon-dark-trans-checked.svg" : "person-icon-dark-trans.svg")} 
          alt="" 
          style={{ width: '40px', height: '40px' }}
        />
        <p style={{
          ...styles.cardText,
          ...(userType === STRING_CONSTANTS.userType.employer ? styles.cardTextActive : {})
        }}>
          I'm a <b>Business Owner</b>
        </p>
      </label>
    </div>
    <div className='radio-button-card-set' style={{ flex: 1 }}>
      <input
        type="radio" 
        name="select_job" 
        id="employee" 
      />
      <label 
        htmlFor="employee" 
        onClick={() => setUserType(STRING_CONSTANTS.userType.employee)}
        style={{
          ...styles.radioButtonCard,
          ...(userType === STRING_CONSTANTS.userType.employee ? styles.radioButtonCardActive : {})
        }}
      >
        <img 
          src={UtilityFunctions.getImageURL() + (userType === STRING_CONSTANTS.userType.employee ? 
            "briefcase-icon-dark-checked.svg" : "briefcase-icon-dark.svg")} 
          alt="" 
          style={{ width: '40px', height: '40px' }}
        />
        <p style={{
          ...styles.cardText,
          ...(userType === STRING_CONSTANTS.userType.employee ? styles.cardTextActive : {})
        }}>
          I'm an <b>Employee</b>
        </p>
      </label>
    </div>
  </div>
</div>
          {/* <div className="btn-maccount mobile-btn d-block d-md-none">
              <button type='button' onClick={() => props.history.push(STRING_CONSTANTS.navigationString.login)}>
                {STRING_CONSTANTS.signupPages.signup.account_exists}
              </button>
            </div> */}
          <div className="next-wrap">
            <button
              className={`${userType ? "cricle-next" : "cricle-next-inactive"}`}
              onClick={() => signupNavigator()}
            >
              <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
        <div className="right-wrap">
          <div className="btn-maccount">
            <button 
              type="button"
              style={styles.accountButton}
              onClick={() => props.props.history.push(STRING_CONSTANTS.navigationString.login)}
            >
              {STRING_CONSTANTS.signupPages.signup.account_exists}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
}
import React, { useState } from 'react';
import './HRAssisstent.css'; 

const HRAssisstent = () => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };

  return (
    <div className="hr-interview container-fluid">
     
      <div className="row justify-content-center m-0">
        <div className="col-12 text-center">
          <img src="/assets/images/mploi-logo-white.svg" alt="MPLOI Logo" className="logo-img" />
          <div className="heading">Welcome to the Abu Dhabi Legal Expert</div>
        </div>
      </div>

      <div className="row profile-sec-wrap">
       
        <div className="col-12 col-md-5 text-center">
          <div className="profile-section">
          <div className='arrow-img-wrap'>
            <img src="/assets/images/arrow-line.png" alt="" /> 
          </div>
            <img src="/assets/images/user-1.png" alt="User" className="profile-img" />
            <div className='user-name'>You<br /> <span></span> </div>
            <div className="icons">
              <button onClick={handleAudioToggle} className="icon-button">
                <img
                  src={isAudioActive ? "/assets/images/audio-off.png" : "/assets/images/audio-on.png"}
                  alt="Audio"
                  className="icon-img"
                />
              </button>
              <button onClick={handleCameraToggle} className="icon-button">
                <img
                  src={isCameraActive ? "/assets/images/vdo-off.png" : "/assets/images/vdo-on.png"}
                  alt="Camera"
                  className="icon-img"
                />
              </button>
            </div>
            <div className='icon-title'>Click to allow access</div>
          </div>
        </div>

        
        <div className="col-12 col-md-5 text-center">
          <div className="profile-section">
            <img src="/assets/images/user-2.png" alt="Manager" className="profile-img" />
            <div className='caller-title'>You will be speaking with:</div>
            <div className='caller-name'>Jenny, MPLOI Legal Assistant</div>
          </div>
        </div>
      </div>

     
      <div className="row bottom-content m-0">
        <div className="col-12 text-center">
            <div className='description'>Please press 'start' to begin and make sure you<b> allow access to your camera and microphone. </b></div>
          <button className="btn prepare-button">Start</button>
          
        </div>
      </div>
    </div>
  );
};

export default HRAssisstent;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';

// Available voices for the AI
const AVAILABLE_VOICES = [
    { id: 'aGkVQvWUZi16EH8aZJvT', name: 'Steve', desc: '', default: true, url: 'https://storage.googleapis.com/eleven-public-prod/custom/voices/aGkVQvWUZi16EH8aZJvT/hUdpJvUH4knIjWwEeGyu.mp3'},
    { id: 'gZ25QC7GHcbM4iwAqulk', name: 'Tim', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/UqlmS3fqKkPB3Kxe6l43H9rmZBr1/voices/gZ25QC7GHcbM4iwAqulk/tjbX787FFYkEx6ZBk7Jy.mp3'},
    { id: '319bKIhetA5g6tmywrwj', name: 'Gemma', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/B6XKo1XG4CdCYGvSECuh2iFjzTq1/voices/319bKIhetA5g6tmywrwj/f40d37fb-ac3b-4914-aaf8-f79ff1e40563.mp3'},
    { id: 'JGIWhSd306fVRtZfD1OA', name: 'Alex', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/q8zuFD4ZjXYZNb8cWTy9Ou0CtaL2/voices/JGIWhSd306fVRtZfD1OA/22ec892b-347f-48dc-9acd-a6d846f31f89.mp3'},
    { id: 'd9rRtAT3qedrNbvmbykg', name: 'Will', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/custom/voices/d9rRtAT3qedrNbvmbykg/jvwceYyY7TMLBHNNRuFF.mp3'},
    { id: 'MiueK1FXuZTCItgbQwPu', name: 'Maya', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/custom/voices/MiueK1FXuZTCItgbQwPu/dY0JGbhvAH2WTEuoj9Bu.mp3'},
    { id: 'M7ya1YbaeFaPXljg9BpK', name: 'Hannah Jayne', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/7tvaSRybYYZ7ja2RTmrVkfJ75f93/voices/M7ya1YbaeFaPXljg9BpK/8Wc09F9557roFsMSjG9E.mp3'},
    { id: '2nzji8yPQooBwG4eQO4s', name: 'John C', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/65SEE4Sx3cgZtuy8loIdatKOCAP2/voices/2nzji8yPQooBwG4eQO4s/IIzySX64Ezkj4ylWqrl5.mp3'},
    { id: 'NihRgaLj2HWAjvZ5XNxl', name: 'Matilda', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/custom/voices/NihRgaLj2HWAjvZ5XNxl/OrIdbMk7IHZvEOEVlsQ0.mp3'},
    { id: 'sai9UY7iXkRDSsXHR0bZ', name: 'Ben', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/IbGEr7k62hYnUndiEtdtV54pnbs1/voices/sai9UY7iXkRDSsXHR0bZ/5NrxCi9pzZbyENe8FAo9.mp3'},
    { id: 'HDA9tsk27wYi3uq0fPcK', name: 'Stuart', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/3LUrnzimItRhFJyBGCNQfSc602Y2/voices/HDA9tsk27wYi3uq0fPcK/4AhIQcGzr8aDvnONN3bh.mp3'},
    { id: 'p43fx6U8afP2xoq1Ai9f', name: 'Emily', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/custom/voices/p43fx6U8afP2xoq1Ai9f/zs7hPpzFI1bDorvMkY1S.mp3'},
    { id: 'mWNaiDAPDAx080ro4nL5', name: 'Baz', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/4jd2taMCJEbpEc9UOOIkqbnr25f2/voices/mWNaiDAPDAx080ro4nL5/KpqGhJNeegsctFx6I88k.mp3'},
    { id: 'v7AjIzCg6vdhCmXwBrb1', name: 'Ian', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/ewdfKQmq34ecRnjie0F9o1SFEFs2/voices/v7AjIzCg6vdhCmXwBrb1/bXYrWiWIErO8NxtmqRi4.mp3'},
    { id: 'sx8pHRzXdQfuUYPGFK7X', name: 'Anna', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/Ib8YRLFGZcfUWFZbcvIMMjy0fv93/voices/sx8pHRzXdQfuUYPGFK7X/yp6G4CygiVVHU2jFJHLK.mp3'},
    { id: 'nBoLwpO4PAjQaQwVKPI1', name: 'Amelia', desc: '', url: 'https://storage.googleapis.com/eleven-public-prod/database/user/zMfVYhWBQcWhDcafePbjm8CD5Lx2/voices/nBoLwpO4PAjQaQwVKPI1/pEX4zUYPQmsyqSF5AcW4.mp3'},
];

// Personality types
const PERSONALITY_TYPES = [
    { id: 'professional', name: 'Professional', description: 'Formal and business-like approach' },
    { id: 'friendly', name: 'Friendly', description: 'Warm and approachable manner' },
    { id: 'empathetic', name: 'Empathetic', description: 'Understanding and compassionate' },
    { id: 'assertive', name: 'Assertive', description: 'Direct and confident approach' },
    { id: 'diplomatic', name: 'Diplomatic', description: 'Tactful and considerate' },
];

// Follow-up frequencies
const FOLLOW_UP_FREQUENCIES = [
    { id: 'weekly', name: 'Weekly', days: 7 },
    { id: 'biweekly', name: 'Every Two Weeks', days: 14 },
    { id: 'monthly', name: 'Monthly', days: 30 },
];
// Styles object
const styles = {
    // Add to your styles object
    backButton: {
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        }
    },
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    input: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '4px',
        color: '#ffffff',
        padding: '8px 12px',
        width: '100%',
        marginBottom: '15px',
    },
    select: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '4px',
        color: '#ffffff',
        padding: '8px 12px',
        width: '100%',
        marginBottom: '15px',
        cursor: 'pointer',
    },
    label: {
        color: 'rgba(255, 255, 255, 0.8)',
        marginBottom: '8px',
        display: 'block',
    },
    switch: {
        position: 'relative',
        display: 'inline-block',
        width: '60px',
        height: '34px',
        marginLeft: '10px',
    },
    switchInput: {
        opacity: 0,
        width: 0,
        height: 0,
    },
    switchSlider: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '34px',
        transition: '0.4s',
    },
    switchSliderChecked: {
        backgroundColor: '#06EFC5',
    },
    switchKnob: {
        position: 'absolute',
        content: '""',
        height: '26px',
        width: '26px',
        left: '4px',
        bottom: '4px',
        backgroundColor: 'white',
        borderRadius: '50%',
        transition: '0.4s',
    },
    switchKnobChecked: {
        transform: 'translateX(26px)',
    },
    section: {
        marginBottom: '30px',
    },
    sectionTitle: {
        color: '#ffffff',
        fontSize: '18px',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    button: {
        padding: '8px 16px',
        borderRadius: '4px',
        border: 'none',
        marginRight: '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#fff',
    },
    buttonPrimary: {
        backgroundColor: '#007bff',
    },
    buttonSuccess: {
        backgroundColor: '#28a745',
    },
    previewButton: {
        backgroundColor: 'rgba(6, 239, 197, 0.1)',
        border: '1px solid #06EFC5',
        color: '#06EFC5',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '15px',
        transition: 'all 0.3s ease',
        height: '35px',
        margin: '0 0 0 15px',
    },
    errorText: {
        color: '#ff4444',
        fontSize: '12px',
        marginTop: '4px',
        marginBottom: '8px',
    },
    loadingOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    loadingSpinner: {
        color: '#06EFC5',
        fontSize: '24px',
    },
    saveButton: {
        backgroundColor: 'rgba(6, 239, 197, 0.1)',
        border: '1px solid #06EFC5',
        color: '#06EFC5',
        padding: '12px 24px',
        fontSize: '16px',
        transition: 'all 0.3s ease',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(6, 239, 197, 0.2)',
        },
        '&:disabled': {
            opacity: 0.6,
            cursor: 'not-allowed',
        },
    },
} as const;

const ToggleSwitch = ({ isOn, handleToggle, disabled = false }) => {
    return (
      <div style={styles.switch} onClick={handleToggle}>
        <input
          type="checkbox"
          checked={isOn}
          disabled={disabled}
          style={styles.switchInput}
        />hi
        <span 
          style={{
            ...styles.switchSlider,
            ...(isOn ? styles.switchSliderChecked : {}),
            ...(disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {})
          }}
        >
          <span 
            style={{
              ...styles.switchKnob,
              ...(isOn ? styles.switchKnobChecked : {})
            }} 
          />
        </span>
      </div>
    );
};

const AccountsReceivableSettings = (props) => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState<any>();
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    

    // Basic Settings States
    const [selectedVoice, setSelectedVoice] = useState('sarah');
    const [personality, setPersonality] = useState('professional');
    const [allowIncomingCalls, setAllowIncomingCalls] = useState(true);
    const [isPlayingPreview, setIsPlayingPreview] = useState(false);

    // Phone Settings States
    const [knownCustomerGreeting, setKnownCustomerGreeting] = useState(
        "Hello {customer_name}, this is {agent_name} from {company_name} regarding your outstanding invoice."
    );
    const [unknownCustomerGreeting, setUnknownCustomerGreeting] = useState(
        "Hello, this is {agent_name} from {company_name}. Am I speaking with someone from your accounts department?"
    );

    // Call Frequency States
    const [initialWaitDays, setInitialWaitDays] = useState(7);
    const [followUpFrequency, setFollowUpFrequency] = useState('weekly');
    const [maxAttempts, setMaxAttempts] = useState(5);
    const [preferredCallTimes, setPreferredCallTimes] = useState({
        start: '09:00',
        end: '17:00',
        timezone: 'Australia/Sydney'
    });

    // Notification Settings States
    const [notificationEmail, setNotificationEmail] = useState('');
    const [notificationPreferences, setNotificationPreferences] = useState({
        invoicePaid: true,
        callMade: false,
        paymentPlanCreated: true,
        negativeOutcome: true
    });

    // Payment Receipt Settings States
    // const [paymentSettings, setPaymentSettings] = useState({
    //     portalUrl: '',
    //     bankAccount: '',
    //     bsb: '',
    //     allowCreditCard: true
    // });

    // Privacy Settings States
    const [storeCallRecordings, setStoreCallRecordings] = useState(true);
    const [recordingRetentionDays, setRecordingRetentionDays] = useState(90);

    const state = useSelector((state: State) => state);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    const [recordingAnnouncement, setRecordingAnnouncement] = useState(
        "This call may be recorded for quality assurance and training purposes. By continuing with this call, you consent to the recording."
    );

    const handleCollectionsToggle = async (checked) => {
        try {
            setIsSaving(true);
            
            // Update local state immediately for responsive UI
            setAllowIncomingCalls(checked);
            
            // Send update to backend using aiRequest instead of xero
            await userService.aiRequest({
                method: 'save-ar-settings',
                data: {
                    id: user.id,
                    settings: {
                        collections_enabled: checked,
                    }
                },
            }, true);
            
            SuccessToast("Success", `Collections ${checked ? 'enabled' : 'disabled'} successfully`);
        } catch (error) {
            // Revert local state if save fails
            setAllowIncomingCalls(!checked);
            ValidationErrorToast("Error", "Failed to update collections status");
            console.error("Toggle error:", error);
        } finally {
            setIsSaving(false);
        }
    };

    // Validation functions
    const validateSettings = (): string[] => {
        const errors: string[] = [];

        // Basic validation
        if (!knownCustomerGreeting.includes('{customer_name}') || 
            !knownCustomerGreeting.includes('{company_name}')) {
            errors.push("Known customer greeting must include {customer_name} and {company_name}");
        }

        if (!unknownCustomerGreeting.includes('{company_name}')) {
            errors.push("Unknown customer greeting must include {company_name}");
        }

        // Call frequency validation
        if (maxAttempts < 1 || maxAttempts > 10) {
            errors.push("Maximum attempts must be between 1 and 10");
        }

        // Email validation
        if (notificationEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(notificationEmail)) {
            errors.push("Invalid notification email format");
        }

        // Payment settings validation
        // if (paymentSettings.portalUrl && 
        //     !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(paymentSettings.portalUrl)) {
        //     errors.push("Invalid payment portal URL format");
        // }

        // if (paymentSettings.bsb && !/^\d{6}$/.test(paymentSettings.bsb)) {
        //     errors.push("BSB must be exactly 6 digits");
        // }

        // Privacy settings validation
        // if (storeCallRecordings && (recordingRetentionDays < 1 || recordingRetentionDays > 365)) {
        //     errors.push("Recording retention days must be between 1 and 365");
        // }

        return errors;
    };

    // Load settings from API
    const loadSettings = async () => {
        setIsLoading(true);
        try {
            const response = await userService.aiRequest({
                method: 'get-ar-settings',
                data: {
                    id: user.id,
                },
            }, true);
            console.log("RESPONSE FROM GETTING SETTINGS IS....", response)
            if (response.data.settings) {
                const st = response.data.settings;
                //                 aiPersonality
                // : 
                // "professional"
                // aiVoice
                // : 
                // "sarah"
                // collectionsEnabled
                // : 
                // true
                // followUpFrequency
                // : 
                // "weekly"
                // initialWaitDays
                // : 
                // 7
                // knownCustomerGreeting
                // : 
                // "Hello {customer_name}, this is {agent_name} from {company_name} regarding your outstanding invoice."
                // maxAttempts
                // : 
                // 5
                // notificationEmail
                // : 
                // ""
                // notificationPreferences
                // : 
                // callMade
                // : 
                // false
                // invoicePaid
                // : 
                // true
                // negativeOutcome
                // : 
                // true
                // paymentPlanCreated
                // : 
                // true
                // [[Prototype]]
                // : 
                // Object
                // paymentPlanSettings
                // : 
                // allowPaymentPlans
                // : 
                // true
                // maxInstallments
                // : 
                // 6
                // minInitialPaymentPercent
                // : 
                // 25
                // minInstallmentAmount
                // : 
                // 100
                // requiresApproval
                // : 
                // true
                // [[Prototype]]
                // : 
                // Object
                // paymentSettings
                // : 
                // allowCreditCard
                // : 
                // true
                // bankAccount
                // : 
                // ""
                // bsb
                // : 
                // ""
                // portalUrl
                // : 
                // ""
                // [[Prototype]]
                // : 
                // Object
                // preferredCallTimeEnd
                // : 
                // "17:00"
                // preferredCallTimeStart
                // : 
                // "09:00"
                // recordingRetentionDays
                // : 
                // 90
                // storeCallRecordings
                // : 
                // true
                // timezone
                // : 
                // "Australia/Sydney"
                // unknownCustomerGreeting
                // : 
                // "Hello, this is {agent_name} from {company

                setSelectedVoice(st.aiVoice);
                setPersonality(st.aiPersonality);
                setAllowIncomingCalls(st.collectionsEnabled);
                setKnownCustomerGreeting(st.knownCustomerGreeting);
                setUnknownCustomerGreeting(st.unknownCustomerGreeting);
                setInitialWaitDays(st.initialWaitDays);
                setFollowUpFrequency(st.followUpFrequency);
                setMaxAttempts(st.maxAttempts);
                // setPreferredCallTimes({
                //     start: st.preferredCallTimesStart,
                //     end: st.preferredCallTimesEnd,
                // });
                // setNotificationEmail(st.notificationEmail);
                // setNotificationPreferences(st.notificationPreferences);
                // setPaymentSettings(st.paymentSettings);
                setStoreCallRecordings(st.storeCallRecordings);
                // setRecordingRetentionDays(st.recordingRetentionDays);
                setStoreCallRecordings(st.storeCallRecordings ?? true);
                setRecordingAnnouncement(st.recordingAnnouncement || "This call will be recorded.");
    
            }
        } catch (error) {
            console.log("ERROR FROM GETTING SETTINGS IS....", error)
            ValidationErrorToast("Error", "Failed to load settings");
        } finally {
            setIsLoading(false);
        }
    };

    // Save settings to API
    const handleSaveSettings = async () => {
        const errors = validateSettings();
        if (errors.length > 0) {
            setValidationErrors(errors);
            errors.forEach(error => ValidationErrorToast("Validation Error", error));
            return;
        }

        setIsSaving(true);
        try {
            await userService.aiRequest({
                method: 'save-ar-settings',
                data: {
                    id: user.id,
                    settings: {
                        collections_enabled: allowIncomingCalls,
                        ai_voice: selectedVoice,
                        ai_personality: personality,
                        known_customer_greeting: knownCustomerGreeting,
                        unknown_customer_greeting: unknownCustomerGreeting,
                        initial_wait_days: initialWaitDays,
                        follow_up_frequency: followUpFrequency,
                        max_attempts: maxAttempts,
                        // preferred_call_times: preferredCallTimes,
                        // notification_email: notificationEmail,
                        // notification_preferences: notificationPreferences,
                        // payment_settings: paymentSettings,
                        store_call_recordings: storeCallRecordings,
                        // recording_retention_days: recordingRetentionDays,
                        recording_announcement: recordingAnnouncement,
                    }
                },
            }, true);
            
            SuccessToast("Success", "Settings saved successfully");
            setValidationErrors([]);
        } catch (error) {
            console.log("ERROR FROM SAVING SETTINGS IS....", error)
            ValidationErrorToast("Error", "Failed to save settings");
        } finally {
            setIsSaving(false);
        }
    };

    // Handle voice preview
    const handleVoicePreview = async (voiceId) => {
        setIsPlayingPreview(true);
        try {
            // Find the voice URL from our AVAILABLE_VOICES array
            const selectedVoiceData = AVAILABLE_VOICES.find(voice => voice.id === voiceId);
            
            if (selectedVoiceData?.url) {
                // Create and play the audio
                const audio = new Audio(selectedVoiceData.url);
                
                audio.onended = () => {
                    setIsPlayingPreview(false);
                };
    
                audio.onerror = () => {
                    setIsPlayingPreview(false);
                    ValidationErrorToast("Error", "Failed to play voice preview");
                };
    
                await audio.play();
            } else {
                throw new Error("Voice sample not found");
            }
        } catch (error) {
            console.error("Preview error:", error);
            ValidationErrorToast("Error", "Failed to play voice preview");
            setIsPlayingPreview(false);
        }
    };

    // Load initial data
    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount);
                    setUserData(res);
                    loadSettings(); // Load settings after user data is available
                });
        }
    }, []);

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Accounts Receivable Settings</title>
            </Helmet>

            {/* Loading Overlay */}
            {(isLoading || isSaving) && (
                <div style={styles.loadingOverlay}>
                    <div style={styles.loadingSpinner}>
                        {isLoading ? 'Loading settings...' : 'Saving settings...'}
                    </div>
                </div>
            )}

            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} alt="Logo" />
                        </Link>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.chat) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </span>
                        </li>

                        {user?.user?.type === STRING_CONSTANTS.userType.employer && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard/modules">
                                    <img 
                                        src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                        style={{ filter: 'brightness(0) invert(1)' }} 
                                        alt="" 
                                    />
                                    <img 
                                        src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                        style={{ filter: 'brightness(0) invert(1)' }} 
                                        alt="" 
                                    />
                                </Link>
                            </li>
                        )}
                    </div>

                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>

                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } alt="Profile" />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>
                {/* Main Content */}
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            {/* Back Button */}
                            <div style={{marginBottom: '24px', marginTop: '12px'}}>
                                <button 
                                    style={styles.backButton}
                                    onClick={() => props.history.push('/ar')}
                                >
                                    <span style={{fontSize: '18px', lineHeight: '1'}}>←</span> 
                                    <span>Back</span>
                                </button>
                            </div>
                            <div className='title-row'>
                                <h1 style={styles.whiteText}>Collection Settings</h1>
                            </div>

                            {/* Validation Errors */}
                            {validationErrors.length > 0 && (
                                <div style={styles.blueBox}>
                                    <h3 style={{...styles.whiteText, color: '#ff4444'}}>Please fix the following errors:</h3>
                                    <ul>
                                        {validationErrors.map((error, index) => (
                                            <li key={index} style={styles.errorText}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div className="scroll-bar-dash-full margn-top38">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* Basic Settings */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} 
                                                     style={{marginRight: '10px'}} 
                                                     alt="" />
                                                Basic Settings
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '10px'
                                                    }}>
                                                        <span style={styles.mutedText}>Collections enabled</span>
                                                        <ToggleSwitch
                                                            isOn={allowIncomingCalls}
                                                            handleToggle={(newState) => {
                                                                setAllowIncomingCalls(!allowIncomingCalls);
                                                            }}
                                                            disabled={isLoading || isSaving}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>AI Voice</label>
                                                        <div style={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center',
                                                            height: '41px'
                                                        }}>
                                                            <select 
                                                                style={{...styles.select, flex: 1, marginBottom: 0}}
                                                                value={selectedVoice}
                                                                onChange={(e) => setSelectedVoice(e.target.value)}
                                                            >
                                                                {AVAILABLE_VOICES.map(voice => (
                                                                    <option key={voice.id} value={voice.id}>
                                                                        {voice.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <button 
                                                                style={{
                                                                    ...styles.previewButton,
                                                                    height: '35px',
                                                                    margin: '0 0 0 15px'
                                                                }}
                                                                onClick={() => handleVoicePreview(selectedVoice)}
                                                                disabled={isPlayingPreview}
                                                            >
                                                                {isPlayingPreview ? 'Playing...' : 'Preview'}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div style={styles.section}>
                                                        <label style={styles.label}>AI Personality</label>
                                                        <select 
                                                            style={styles.select}
                                                            value={personality}
                                                            onChange={(e) => setPersonality(e.target.value)}
                                                        >
                                                            {PERSONALITY_TYPES.map(type => (
                                                                <option key={type.id} value={type.id}>
                                                                    {type.name} - {type.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Greetings Settings */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} 
                                                     style={{marginRight: '10px'}} 
                                                     alt="" />
                                                Greeting Messages
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>Known Customer Greeting</label>
                                                        <textarea 
                                                            style={{...styles.input, height: '100px'}}
                                                            value={knownCustomerGreeting}
                                                            onChange={(e) => setKnownCustomerGreeting(e.target.value)}
                                                            placeholder="Use {customer_name}, {agent_name}, {company_name} as variables"
                                                        />
                                                        {!knownCustomerGreeting.includes('{customer_name}') && (
                                                            <div style={styles.errorText}>
                                                                Must include {'{customer_name}'} variable
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>Unknown Customer Greeting</label>
                                                        <textarea 
                                                            style={{...styles.input, height: '100px'}}
                                                            value={unknownCustomerGreeting}
                                                            onChange={(e) => setUnknownCustomerGreeting(e.target.value)}
                                                            placeholder="Use {agent_name}, {company_name} as variables"
                                                        />
                                                        {!unknownCustomerGreeting.includes('{company_name}') && (
                                                            <div style={styles.errorText}>
                                                                Must include {'{company_name}'} variable
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Call Frequency Settings */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img src={UtilityFunctions.getImageURL() + "calendar-icon-green.svg"} 
                                                     style={{marginRight: '10px'}} 
                                                     alt="" />
                                                Call Frequency
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>Follow up invoices after</label>
                                                        <select 
                                                            style={styles.select}
                                                            value={initialWaitDays}
                                                            onChange={(e) => setInitialWaitDays(Number(e.target.value))}
                                                        >
                                                            <option value={7}>1 Week Overdue</option>
                                                            <option value={14}>2 Weeks Overdue</option>
                                                            <option value={30}>1 Month Overdue</option>
                                                            <option value={90}>3 Months Overdue</option>
                                                        </select>
                                                    </div>
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>Follow-up Frequency</label>
                                                        <select 
                                                            style={styles.select}
                                                            value={followUpFrequency}
                                                            onChange={(e) => setFollowUpFrequency(e.target.value)}
                                                        >
                                                            {FOLLOW_UP_FREQUENCIES.map(freq => (
                                                                <option key={freq.id} value={freq.id}>
                                                                    {freq.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <label style={styles.label}>Maximum Attempts</label>
                                                        <input 
                                                            type="number" 
                                                            style={styles.input}
                                                            value={maxAttempts}
                                                            onChange={(e) => setMaxAttempts(Number(e.target.value))}
                                                            min={1}
                                                            max={10}
                                                        />
                                                        {(maxAttempts < 1 || maxAttempts > 10) && (
                                                            <div style={styles.errorText}>
                                                                Must be between 1 and 10 attempts
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div style={styles.section}>
                                                        <label style={styles.label}>Preferred Call Times</label>
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <input 
                                                                type="time" 
                                                                style={{...styles.input, width: '45%'}}
                                                                value={preferredCallTimes.start}
                                                                onChange={(e) => setPreferredCallTimes(prev => ({
                                                                    ...prev,
                                                                    start: e.target.value
                                                                }))}
                                                            />
                                                            <input 
                                                                type="time" 
                                                                style={{...styles.input, width: '45%'}}
                                                                value={preferredCallTimes.end}
                                                                onChange={(e) => setPreferredCallTimes(prev => ({
                                                                    ...prev,
                                                                    end: e.target.value
                                                                }))}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Privacy Settings */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img src={UtilityFunctions.getImageURL() + "shield-icon-green.svg"} 
                                                    style={{marginRight: '10px'}} 
                                                    alt="" />
                                                Privacy Settings
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div style={styles.section}>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: '20px'
                                                        }}>
                                                            <span style={styles.mutedText}>Store Call Recordings</span>
                                                            <div style={styles.switch} onClick={(e) => setStoreCallRecordings(!storeCallRecordings)}>
                                                                <input 
                                                                    type="checkbox"
                                                                    checked={storeCallRecordings}
                                                                    style={styles.switchInput}
                                                                />
                                                                <span style={{
                                                                    ...styles.switchSlider,
                                                                    ...(storeCallRecordings ? styles.switchSliderChecked : {})
                                                                }}>
                                                                    <span style={{
                                                                        ...styles.switchKnob,
                                                                        ...(storeCallRecordings ? styles.switchKnobChecked : {})
                                                                    }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {storeCallRecordings && (
                                                    <div className="col-md-6">
                                                        <div style={styles.section}>
                                                            <label style={styles.label}>Recording Announcement</label>
                                                            <textarea 
                                                                style={{...styles.input, height: '100px'}}
                                                                value={recordingAnnouncement || "This call may be recorded for quality assurance and training purposes. By continuing with this call, you consent to the recording."}
                                                                onChange={(e) => setRecordingAnnouncement(e.target.value)}
                                                                placeholder="Enter recording disclosure message"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Save Button */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                            <button 
                                                style={{
                                                    ...styles.saveButton,
                                                    opacity: isLoading || isSaving ? 0.6 : 1,
                                                    cursor: isLoading || isSaving ? 'not-allowed' : 'pointer'
                                                }}
                                                onClick={handleSaveSettings}
                                                disabled={isLoading || isSaving}
                                            >
                                                {isSaving ? 'Saving...' : 'Save Settings'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountsReceivableSettings;

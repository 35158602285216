import React, { useEffect, useState } from "react";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import ValidationErrorToast from "../../components/validationErrorToast";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../../_services";
import { get, isEmpty } from "lodash";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer } from "react-bootstrap";
import { Helmet } from 'react-helmet';

const BusinessContact = (props: any) => {

    const [contactName, setContactName] = useState<any>("")
    const [phone, setPhone] = useState<any>();
    const [btnClick, setBtnClick] = useState(false);

    useEffect(() => {
        let contact = get(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user), 'contact', null)
        if (get(contact, 'mobileNumber')) {
            let numberWithoutCode = (get(contact, 'mobileNumber'));
            setPhone(numberWithoutCode);
        }
        if (get(contact, 'name')) {
            setContactName(get(contact, 'name'));
        }
    }, [])

    let completeProfile = () => {
        userService.userProfile({ user: { is_profile_completed: true } }).then(res => {
            cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        }
        ).catch(err => ToastContainer(err))
    }

    const contactSubmitHandler = () => {
        setBtnClick(true);
        if (contactName && phone && contactName.replace(/ /g, '').length > 0) {
            var formData = {
                "contact": {
                    "name": contactName,
                    "mobileNumber": phone
                }
            }
            userService.userProfile(formData)
                .then((res) => {
                    completeProfile()
                    props.nav.next();
                })
                .catch(e => {
                    // console.log(e)
                    ValidationErrorToast(e[0], e[1])
                })
        }
        else if (!contactName) {
            ValidationErrorToast('Contact Name', "This field can't be empty!")
        }
        else if (!phone) {
            ValidationErrorToast('Contact Number', "This field can't be empty!")
        }
    }

    return (
        <>
      <Helmet>
        <title>MPLOI - Business Contact</title>
      </Helmet>
            <div className='top-divde-spa'>
                <span>
                    <i
                        className="fa fa-angle-left text-white d-flex justify-content-between"
                        aria-hidden="true"
                        onClick={() => {
                            props?.nav?.back()
                        }}
                    ></i>
                </span>
                <div className="step-content signup-mar">
                    <button type='button' disabled>{STRING_CONSTANTS.employerProfileCompletionPages.business_contact.business_contact}</button>
                    <p>{STRING_CONSTANTS.employerProfileCompletionPages.business_contact.enter_contact}</p>
                    <div className="step-text">
                        {STRING_CONSTANTS.employerProfileCompletionPages.business_contact.step}
                    </div>
                </div>
            </div>
            <form className="form-wrap">
                <div className="v-form-width">
                    <div className="form-floating mb-3">
                        <input type='text' className={`form-control ${btnClick && isEmpty(contactName) ? 'error' : ""}`} placeholder='Enter Contact Name' value={contactName} onChange={(e) => { setContactName(e.target.value); setBtnClick(false); }} />
                        <label htmlFor="floatingInput">{STRING_CONSTANTS.formLabelString.label_contact_name}</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "input-user-blank.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "input-user-fill-blue.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M17.7545 13.9997C18.9966 13.9997 20.0034 15.0065 20.0034 16.2486V16.824C20.0034 17.7183 19.6838 18.5831 19.1023 19.2625C17.5329 21.096 15.1457 22.0008 12.0004 22.0008C8.8545 22.0008 6.46849 21.0957 4.90219 19.2614C4.32242 18.5825 4.00391 17.719 4.00391 16.8262V16.2486C4.00391 15.0065 5.01076 13.9997 6.25278 13.9997H17.7545ZM17.7545 15.4997H6.25278C5.83919 15.4997 5.50391 15.835 5.50391 16.2486V16.8262C5.50391 17.3619 5.69502 17.88 6.04287 18.2874C7.29618 19.755 9.26206 20.5008 12.0004 20.5008C14.7387 20.5008 16.7063 19.755 17.9627 18.2871C18.3117 17.8795 18.5034 17.3605 18.5034 16.824V16.2486C18.5034 15.835 18.1681 15.4997 17.7545 15.4997ZM12.0004 2.00439C14.7618 2.00439 17.0004 4.24297 17.0004 7.00439C17.0004 9.76582 14.7618 12.0044 12.0004 12.0044C9.23894 12.0044 7.00036 9.76582 7.00036 7.00439C7.00036 4.24297 9.23894 2.00439 12.0004 2.00439ZM12.0004 3.50439C10.0674 3.50439 8.50036 5.0714 8.50036 7.00439C8.50036 8.93739 10.0674 10.5044 12.0004 10.5044C13.9334 10.5044 15.5004 8.93739 15.5004 7.00439C15.5004 5.0714 13.9334 3.50439 12.0004 3.50439Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V17.1673C20.0032 17.7406 19.8239 18.2997 19.4906 18.7662C17.9447 20.9294 15.4204 22.0011 12.0001 22.0011C8.57915 22.0011 6.05619 20.9289 4.51403 18.7646C4.18207 18.2987 4.00366 17.7409 4.00366 17.1688V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                    <div className="form-wrap text-start mt-4">

                        <div className="input-group mb-3">
                            <PhoneInput
                                country={'au'}
                                masks={{ au: '... ... ....', in: '...........' }}
                                placeholder="00 000 000"
                                countryCodeEditable={false}
                                value={phone}
                                onChange={phone => setPhone('+' + phone)}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className="next-wrap">
                <button
                    className={
                        phone?.length > 10 && contactName ?
                            "cricle-next"
                            : "cricle-next-inactive"
                    }
                    onClick={() =>
                        // props.nav.next()
                        contactSubmitHandler()
                    }
                >
                    <img
                        alt=""
                        src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                    />
                </button>
            </div>
        </>
    )
}


export default withRouter(BusinessContact);
// "use strict";
import secureLocalStorage from "react-secure-storage";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";

/**
 * 
NOT USING COOKIES/ ONLY USING LOCAL STORAGE (ENCRYPTED)
*/
const storage =
  process.env.REACT_APP_PRODUCTION === "TRUE"
    ? secureLocalStorage
    : localStorage;

export const cookieService = {
  saveCookie,
  getCookie,
  removeAllCookies,
};

function saveCookie(cookieName: string, value: any, options = null) {
  //   const cookieOptions = options
  //     ? options
  //     : {
  //         path: "/",
  //         sameSite: true,
  //         secure: false, // If we enable secure true then site must runs on https. otherwise it didn't work with http request.
  //       };
  storage.setItem(cookieName, JSON.stringify(value));
  //   cookie.save(cookieName, value, cookieOptions);
}

function getCookie(cookieName: string) {
  const value = storage.getItem(cookieName) as string;
  return value !== "undefined" ? JSON.parse(value) : null;
}

function removeAllCookies(options = null) {
  const cookieItems = [
    STRING_CONSTANTS.cookieStrings.userData,
    STRING_CONSTANTS.cookieStrings.token,
    STRING_CONSTANTS.cookieStrings.user,
    STRING_CONSTANTS.cookieStrings.planSelected,
    // STRING_CONSTANTS.cookieStrings.apiError,
    STRING_CONSTANTS.cookieStrings.jobCategories,
    "unreadCount",
  ];
  for (let i = 0; i < cookieItems.length; i++) {
    const cookieName = cookieItems[i] + "";
    storage.removeItem(cookieName);
  }
  return true;
}

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import "./subscription.css";
import Switch from "react-switch";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import PaymentProcedure from "./PaymentProcedure";
import { api } from "../../../middleware/api";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { cookieService } from "../../../_services";


const SubscriptionModal = (props: any) => {
  const [toggle, setToggle] = useState(true);
  const [subsDataMonth, setSubsDataMonth] = useState([]);
  const [subsDataYearly, setSubsDataYearly] = useState([]);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(null);
  const [isCardAdded, setIsCardAdded] = useState(false);
  const [productId, setProductId] = useState("");
  const [subscriptionStep, setSubscriptionStep] = useState(1);
  const [continuePay, setContinuePay] = useState(false);
  const [subsPlanData, setSubsPlanData] = useState<any>();
  const [couponCodeValue, setCouponCodeValue] = useState("");
  const [promoData, setPromoData] = useState<any>();

  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);

  useEffect(() => {
    if (props.cardId !== undefined) {
      setProductId(props.cardId)
      handleSubsProductIntermediary(props.cardId)
    }
  }, [])

  const handleSubsProductIntermediary = (product_id) => {
    console.log('handleSubsProductIntermediary', product_id)
    api.get(`subscription/product-detail/?product_id=${product_id}`, {
      "product_id": product_id,
    }).then((data) => {
      if (data) {
        setSubsPlanData(data);
        setSubscriptionStep(2);
      }
    })
      .catch((err) => { console.log("err", err) });
  }
  const handleSubsProduct = () => {
    console.log('handleSubsProduct', productId)
    api.get(`subscription/product-detail/?product_id=${productId}`, {
      "product_id": productId,
    }).then((data) => {
      if (data) {
        // setIsPaymentOpen(true)
        setSubsPlanData(data);
        setSubscriptionStep(2);
      }
    })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handleSubsProductNew = (product_id, promo_code) => {
    console.log('handleSubsProductNew', product_id)
    api.get(`subscription/product-detail/?product_id=${product_id}`, {
      "product_id": product_id,
    }).then((data) => {
      if (data) {
        setSubsPlanData(data);
        setSubscriptionStep(2);
        handleCouponCodeNew(product_id, promo_code)
      }
    })
      .catch((err) => { console.log("err", err) });
  }
  const handleCouponCodeNew = (product_id, coupon_code) => {
    console.log(coupon_code);
    if (coupon_code) {
      api.post(`subscription/discount/`, {
        "product_id": product_id,
        "promo_code": coupon_code,
      }).then(
        (res) => {
          if (res.data) {
            setPromoData(res.data);
          } else {
            ValidationErrorToast("invalid promo code", "");
          }
        }
      )
        .catch(
          (err) => {
            if (err[1] != undefined && typeof err[1] === 'string') {
              ValidationErrorToast(err[1], "");
            } else {
              ValidationErrorToast("Some problem occured while applying promocode", "");
            }
          }
        );
      localStorage.removeItem("allPromoData");
    } else {
      ValidationErrorToast("invalid promo code", "");
    }
  }

  const handleCouponCode = () => {
    console.log(couponCodeValue);
    if (couponCodeValue) {
      api.post(`subscription/discount/`, {
        "product_id": productId,
        "promo_code": couponCodeValue,
      }).then(
        (res) => {
          // console.log("res", res.data);
          if (res.data) {
            setPromoData(res.data)
          } else {
            ValidationErrorToast("invalid promo code", "");
          }
        }
      )
        .catch(
          (err) => {
            if (err[1] != undefined && typeof err[1] === 'string') {
              ValidationErrorToast(err[1], "");
            } else {
              ValidationErrorToast("Some problem occured while applying promocode", "");
            }
          }
        );
    } else {
      ValidationErrorToast("invalid promo code", "");
    }
  }
  // useEffect(() => {
  //   if (props.toggleValue === false) {
  //     setToggle(false)
  //   } else {
  //     setToggle(true)
  //   }
  // })
  const handlePaymentOption = () => {
    handleCreateSubscription()
    // setIsCardAdded(true);
    // setIsPaymentOpen(true);
    // api
    //   .get(`subscription/card-status/`, {})
    //   .then((data) => {
    //     setIsPaymentOpen(true);
    //     if (data?.paymentMethodAttached == true) {
    //       setIsCardAdded(true);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  useEffect(() => {
    setTimeout(() => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let promo_code_url = params.get('promo_code');
      let product_id_url = params.get('product_id');
      if (promo_code_url && product_id_url) {
        setProductId(product_id_url);
        setCouponCodeValue(promo_code_url);
        handleSubsProductNew(product_id_url, promo_code_url)
      }
    }, 1500);

  }, [])

  useEffect(() => {
    setSubscriptionStep(1)
  }, [props.subsModal])

  useEffect(() => {
    console.log("MY INITIAL")
    api
      .get(`subscription/products/?subscription_type=month`, {})
      .then((data) => {
        console.log("RESPONSE DATA FOR MONTHLY IS", data)
        setSubsDataMonth(data);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(`subscription/products/?subscription_type=year`, {})
      .then((data) => {
        console.log("RESPONSE DATA FOR YEARLY IS", data)
        setSubsDataYearly(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("allPromoData"));
    if (data) {
      const dataArray = data?.split("&");
      const promoCodeUrl = dataArray[1]?.split("=")[1];
      const productIdUrl = dataArray[2]?.split("=")[1];
      if (promoCodeUrl && productIdUrl) {
        setProductId(productIdUrl);
        setCouponCodeValue(promoCodeUrl);
        handleSubsProductNew(productIdUrl, promoCodeUrl)
      }
    }

  }, [])

  const handlePlanSelected = (index) => {
    setIsPlanSelected(index);
    setContinuePay(true);

    if (toggle) {
      setProductId(subsDataYearly[index].id);
    } else {
      setProductId(subsDataMonth[index].id);
    }
  };


  const handlePayment = () => {
    if (isPlanSelected != null) {
      setIsPaymentOpen(!isPaymentOpen);
    }
    // setIsPlanSelected(null);
  };

  const handleToggle = () => {
    setIsPlanSelected(null);
    setToggle(!toggle);
  };

  const handleCreateSubscription = () => {
    api.post(`subscription/`, {
      "product_id": productId,
      "promo_code": couponCodeValue
    })
      .then((data) => {
        if (data.data.checkoutUrl && data.data.checkoutUrl !== undefined && data.data.checkoutUrl != "") {
          window.location.href = data.data.checkoutUrl
        } else {
          ValidationErrorToast("Something went wrong while making payment.", "Payment error");
        }
      })
      .catch((err) => {
        ValidationErrorToast("Something went wrong while making payment.", "Payment error");
      });
  }

  return (
    <>
      <Modal show={props.subsModal} fullscreen={true} className="subs">
        {subscriptionStep === 1 ?
          <div className="sub-1">
            <div className="bg-img  position-relative">
              <div className="row sub-row">
                <div className="col-md-2 col-auto">
                  <img src={UtilityFunctions.getImageURL() + "logo_White.svg"} />
                </div>
                <div className="col-md-8 column-three">
                  <div className="title">Subscription Plan</div>
                  <div className="plan">Choose the plan based on the size of your team.</div>

                </div>
                <div className="col-md-1 col-7" style={{ justifyContent: 'right', display: 'flex' }}>
                  <i className="fa fa-times cross" onClick={() => {
                    props.setSubsModal(false)
                    if (props.history) {
                      props.history.push('/dashboard/settings')
                    }
                  }}></i>
                </div>
              </div>

              <div className="row mt-4 justify-cent">
                <div className="col-auto mt-4 mon">Monthly</div>

                <div className="col-auto mt-4">
                  <Switch checked={toggle} onChange={() => handleToggle()}
                    onColor='#110128' offColor="#110128" uncheckedIcon={false} checkedIcon={false}
                  />
                </div>
                <div className="col-auto mt-4" style={{ textAlign: 'left' }}>
                  <div className="mon">Yearly</div>
                  <div className="mon">(get 20% discount)</div>
                </div>
              </div>

              <div className="cards-row mt-4">
                {subsDataYearly.map((card, i) => {
                  return (
                    <div key={i} className="">
                      <div className="sub-box">
                        <div className="row w-100 m-auto">
                          <div className="col-md-5 col-6 p-0">
                            <div className="type-box">
                              {/* <div>{toggle ? (card && card.name) : (subsDataMonth[i] && subsDataMonth[i].name)}</div> */}
                              <div>{toggle ? card.name : subsDataMonth[i].name}</div>
                            </div>
                          </div>
                          {/* <div className="col-md-7 col-6 center">
                        <div className="input-w myradio">
                          <input
                            type="radio"
                            name="firstradio"
                            id={"rabt" + i}
                            defaultChecked={false}
                            checked={isPlanSelected == i}
                            onChange={() => handlePlanSelected(i)}
                          />
                          <label htmlFor={"rabt" + i}></label>
                        </div>
                      </div> */}
                        </div>
                        <div className={`price ${toggle ? 'mt-4' : 'mt-5 sub-margin-bottom'} `}>${toggle ? card.prices[0].unitAmount : subsDataMonth[i].prices[0].unitAmount}</div>
                        {toggle &&
                          <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center' }}>
                            <div className="col-auto p-0">
                              <div className="price-strike">${card.originalPrice}</div>
                            </div>
                            <div className="col-auto p-0 center">
                              <div className="row w-100 m-auto">
                                <div className="col-auto p-1 center">
                                  <div className="time">/Yearly</div>
                                </div>
                                <div className="col-auto p-1 center">
                                  <div className="off">({card.discount}% OFF)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        <div className="hr mt-4" />
                        <div className="list mt-4" >
                          <div className="row w-100 list-row mt-3">
                            <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }}  >
                              <img src="/assets/images/Checkmark.png" className="check" />
                            </div>
                            <div className="col-auto" style={{ padding: "0px" }}>
                              <div className="list-item">Unlimited Job Posts</div>
                            </div>
                          </div>
                          <div className="row w-100 list-row mt-3">
                            <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                              <img src="/assets/images/Checkmark.png" className="check" />
                            </div>
                            <div className="col-auto" style={{ padding: "0px" }}>
                              <div className="list-item" >Instant Matching to Candidates </div>
                            </div>
                          </div>
                          <div className="row w-100 list-row mt-3">
                            <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                              <img src="/assets/images/Checkmark.png" className="check" />
                            </div>
                            <div className="col-auto" style={{ padding: "0px" }}>
                              <div className="list-item">Direct Chat with Candidates </div>
                            </div>
                          </div>
                          <div className="row w-100 list-row mt-3">
                            <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }}>
                              <img src="/assets/images/Checkmark.png" className="check" />
                            </div>
                            <div className="col-auto" style={{ padding: "0px" }}>
                              <div className="list-item">Fixed Price. No Add-Ons </div>
                            </div>
                          </div>
                          <div className="row w-100 list-row mt-3">
                            <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                              <img src="/assets/images/Checkmark.png" className="check" />
                            </div>
                            <div className="col-auto" style={{ padding: "0px" }}>
                              <div className="list-item">NO RECRUITMENT AGENCIES</div>
                            </div>
                          </div>
                        </div>
                        <div className={`mt-4 ${isPlanSelected === i ? 'subs-button1-active' : 'button1'}`} onClick={() => handlePlanSelected(i)}>
                          <div className={` ${isPlanSelected === i ? 'subs-btn-text-active' : 'btn-txt'}`}>{isPlanSelected === i ? 'Selected' : 'Choose Plan'}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="">
                  <div className="sub-box">
                    <div className="row w-100 m-auto">
                      <div className="col-md-5 col-6 p-0">
                        <div className="type-box">
                          <div>Over 35 Staff</div>
                        </div>
                      </div>
                      {/* <div className="col-md-7 col-6 center">
                    <div className="input-w myradio">
                      <input
                        type="radio"
                        name="firstradio"
                        id="rabt3"
                        defaultChecked={false}
                        checked={isPlanSelected == subsDataYearly.length + 1}
                        onChange={() => setIsPlanSelected(subsDataYearly.length + 1)}
                      />
                      <label htmlFor="rabt3"></label>
                    </div>
                  </div> */}
                    </div>
                    <div className="price mt-4">Contact Team Sales</div>
                    <div className="hr mt-3" />
                    <div className="list mt-4">
                      <div className="row w-100 list-row mt-3">
                        <div className="col-auto " style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                          <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto" style={{ padding: "0px" }}>
                          <div className="list-item">Unlimited Job Posts</div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-3">
                        <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                          <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto " style={{ padding: "0px" }} >
                          <div className="list-item ">Instant Matching to Candidates</div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-3">
                        <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }}  >
                          <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto" style={{ padding: "0px" }}>
                          <div className="list-item">Direct Chat with Candidates </div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-3">
                        <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }}  >
                          <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto" style={{ padding: "0px" }}>
                          <div className="list-item">Fixed Price. No Add-Ons </div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-3">
                        <div className="col-auto" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                          <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto" style={{ padding: "0px" }}>
                          <div className="list-item">NO RECRUITMENT AGENCIES</div>
                        </div>
                      </div>
                    </div>
                    <a href="mailto:info@mploi.com">
                      {/* <div className={`mt-4 ${isPlanSelected === subsDataYearly.length + 1 ? 'subs-button1-active' : 'button1'}`} onClick={() => setIsPlanSelected(subsDataYearly.length + 1)}>
                    <div className={` ${isPlanSelected === subsDataYearly.length + 1 ? 'subs-btn-text-active' : 'btn-txt'}`}>{isPlanSelected === subsDataYearly.length + 1 ? 'Selected' : 'Contact Sales'}</div>
                  </div> */}
                      <div className="mt-4 button1">
                        <div className="btn-txt">Contact Sales</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {continuePay === true ? <div className="mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="btn-continue mt-4" onClick={() => { handleSubsProduct() }}>
                  <div className="cont ">Continue</div>
                </div>
              </div> : ""}
            </div>
          </div>
          : subscriptionStep === 2 ?
            <div className="sub-2">
              <div className="bg-img  position-relative">
                <div className="row sub-row">
                  <div className="col-md-2 col-auto">
                    <img src={UtilityFunctions.getImageURL() + "logo_White.svg"} />
                  </div>
                  <div className="col-md-8 column-three">
                    <div className="title position-relative">
                      <div className="position-absolute subs-image-container ">
                        <img src="/assets/images/arrow-left.png" className="subs-back-arrow" style={{ width: "24px", color: "white", cursor: "pointer" }} onClick={() => {
                          setPromoData(null)
                          setCouponCodeValue("");
                          setSubscriptionStep(1)
                        }} />
                      </div>Subscription Plan
                    </div>
                    <div className="plan">
                      You are just a step away to get your account registered.
                    </div>
                  </div>
                  <div
                    className="col-md-1 col-7"
                    style={{ justifyContent: "right", display: "flex" }}
                  >
                    <i
                      className="fa fa-times cross"
                      onClick={() => {
                        props.setSubsModal(false);
                        setCouponCodeValue("")
                      }}
                    ></i>
                  </div>
                </div>
                <div
                  className="selectedSubscription container-fluid "
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    padding: "20px",
                  }}
                >
                  <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div
                      className="type-box"
                      style={{
                        border: "0.897px solid white",
                        background: "transparent",
                        width: "210px",
                        height: "34px",
                      }}
                    >
                      <div style={{ color: "White" }}>{subsPlanData?.name}</div>
                    </div>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <input type="radio" checked id="radio" />
                      <div style={{ color: "#FFFFFF", paddingLeft: "5px" }}>{subsPlanData?.price?.recurring?.interval}</div>
                    </div>
                  </div>
                  <div
                    className="d-flex main-div"
                  // style={{ justifyContent: "center", paddingLeft: "10px" }}
                  >
                    <div className="col-md-5 col-6 p-0">
                      <div
                        className=" d-flex col-auto mt-3 p-0"
                        style={{
                          justifyContent: "center",
                          alignItems: "baseline",
                          marginLeft: "30px",
                        }}
                      >
                        <div className="price_nav" style={{ textAlign: "inherit" }}>
                          $ {subsPlanData.price.unitAmount}
                        </div>
                        <div style={{ color: "#FFFFFF" }}>/{subsPlanData?.price.recurring?.interval}</div>
                      </div>
                      <div
                        className="subs-year-text"
                        style={{
                          color: "#FFFFFF",
                          fontSize: "12px",
                          marginTop: "10px",
                        }}
                      >
                        (*Subscribe yearly and get 20% discount)
                      </div>
                    </div>
                    <div className="list-add">
                      <div>
                        <div className="row w-100 list-row mt-1">
                          <div className="col-auto d-flex align-items-center ">
                            <div className="col-auto">
                              <img
                                src="/assets/images/Checkmark.png"
                                className="check"
                              />
                            </div>
                            <div className="col-auto" style={{ paddingLeft: "0px" }}>
                              <div className="list-item">Unlimited Job Posts</div>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100 list-row mt-1">
                          <div className="col-auto d-flex align-items-center ">
                            <div className="col-auto">
                              <img
                                src="/assets/images/Checkmark.png"
                                className="check"
                              />
                            </div>
                            <div className="col-auto" style={{ paddingLeft: "0px" }}>
                              <div className="list-item">Instant Matching to Candidates</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="row w-100 list-row mt-1">
                          <div className="col-auto d-flex align-items-center">
                            <div className="col-auto">
                              <img
                                src="/assets/images/Checkmark.png"
                                className="check"
                              />
                            </div>
                            <div className="col-auto" style={{ paddingLeft: "0px" }}>
                              <div className="list-item">Direct Chat with Candidates</div>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100 list-row mt-1">
                          <div className="col-auto d-flex align-items-center">
                            <div className="col-auto">
                              <img
                                src="/assets/images/Checkmark.png"
                                className="check"
                              />
                            </div>
                            <div className="col-auto" style={{ paddingLeft: "0px" }}>
                              <div className="list-item">Fixed Price. No Add-Ons</div>
                            </div>
                          </div>
                        </div>

                        <div className="row w-100 list-row mt-1">
                          <div className="col-auto d-flex align-items-center">
                            <div className="col-auto">
                              <img
                                src="/assets/images/Checkmark.png"
                                className="check"
                              />
                            </div>
                            <div className="col-auto" style={{ paddingLeft: "0px" }}>
                              <div className="list-item">NO RECRUITMENT AGENCIES</div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div
                    className="hrcard one"
                    style={{ marginLeft: "-15px", width: "244px", position: "absolute", bottom: "0px" }}
                  ></div>
                </div>
                <div className="promoCode">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <input
                      type="text"
                      placeholder="Type coupon code here"
                      className="promo-code"
                      value={couponCodeValue}
                      onChange={(e) => setCouponCodeValue(e.target.value)}
                    />
                    {!promoData &&
                      <div
                        style={{
                          color: "#06EFC5",
                          fontSize: "16px",
                          paddingLeft: "20px",
                          cursor: "pointer"
                        }}
                        onClick={() => handleCouponCode()}
                      >
                        Apply
                      </div>
                    }
                    {promoData &&
                      <div
                        style={{
                          color: "#06EFC5",
                          fontSize: "16px",
                          paddingLeft: "20px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setPromoData(null)
                          setCouponCodeValue("");
                        }}
                      >
                        Remove Coupon
                      </div>
                    }
                  </div>
                  <div
                    style={{ color: "white", paddingTop: "10px", fontSize: "10px" }}
                  >
                    Note: Promo code will be applied only once
                  </div>
                </div>
                <div className="container-fluid margn-top38">
                  <div
                    className="navSubscription container-fluid"
                    style={{ width: "409px" }}
                  >
                    <div className="heading">Price Details</div>
                    <div style={{ padding: "10px 10px" }}>
                      <div className="price-details">
                        <div className="price-details-text">
                          {subsPlanData?.name} Plan {subsPlanData?.price?.recurring?.interval.charAt(0).toUpperCase() + subsPlanData?.price?.recurring?.interval.slice(1)}ly
                        </div>
                        <div className="price-details-text">${subsPlanData.price.unitAmount}</div>
                      </div>
                      {promoData && <div className="price-details">
                        <div className="price-details-text">Discount </div>
                        <div
                          className="price-details-text"
                          style={{ color: "#06EFC5" }}
                        >
                          -${promoData?.price?.discountAmount}
                        </div>
                      </div>}

                      <div className="price-details">
                        <div
                          className="price-details-text"
                          style={{ fontWeight: "500" }}
                        >
                          Pay
                        </div>
                        <div
                          className="price-details-text"
                          style={{ fontWeight: "500" }}
                        >

                          ${promoData ? promoData?.price?.unitAmount : subsPlanData.price.unitAmount}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div>
                      <div className="d-flex" style={{ width: "fit-content", margin: "0px auto", alignItems: "center" }}>
                        {promoData && <>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8.74905 5.50003C8.74905 5.91371 8.41369 6.24907 8.00001 6.24907C7.58633 6.24907 7.25098 5.91371 7.25098 5.50003C7.25098 5.08635 7.58633 4.751 8.00001 4.751C8.41369 4.751 8.74905 5.08635 8.74905 5.50003ZM8 7C8.27614 7 8.5 7.22386 8.5 7.5V10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5V7.5C7.5 7.22386 7.72386 7 8 7Z"
                              fill="#06EFC5"
                            />
                          </svg>

                          <div style={{ color: "#06EFC5", fontSize: "12px", paddingLeft: "5px" }}>
                            You are saving ${promoData?.price?.discountAmount} on the subscription plan
                          </div>
                        </>
                        }
                      </div>

                      <div className="btn-continue mt-1" style={{ width: "350px" }} onClick={() => handlePaymentOption()} >
                        <div className="cont " >Pay ${promoData ? promoData?.price?.unitAmount : subsPlanData.price.unitAmount}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : ""}
        {isPaymentOpen ?
          <div style={{ width: "100vw", height: "100%" }} className=" container-fluid position-absolute outer_container" onClick={() => handlePayment()}>
            <div className=" d-flex align-items-center justify-content-center" style={{ width: "100vw", height: "100vh" }}>
              <PaymentProcedure
                isCardAdded={isCardAdded}
                productId={productId}
                couponCodeValue={couponCodeValue}
              />
            </div>
          </div>
          :
          null}
      </Modal >
    </>
  );
};

export default SubscriptionModal;

import React, { useRef, useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./HrAvatarMode.css";
import ChatMessages from "../../../components/ChatMessages/ChatMessages";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import { Helmet } from 'react-helmet';
import { set } from "immutable";

const DID_API = {
  "key": "Y2hyaXNAb3J0dG8uY29t:hxGNhsq2j6IRgirOD_54K",
  "url": "https://api.d-id.com",
  "service": "talks"
};

const RTCPeerConnection = (
  window.RTCPeerConnection ||
  window.webkitRTCPeerConnection ||
  window.mozRTCPeerConnection
).bind(window);

const chatData = [
  {
    sender: "Jenny HR Manager",
    text: `I will need some more information: <br/> 1. What are the names of the parties<br/>2. What date did the incident occur?<br/>3. Who will witness the document?`,
    attachments: [],
    avatar: "/assets/images/hr-manager-chat.png",
  },
  {
    sender: "John Jones (you)",
    text: "No worries. See attached the pdf with more information which might help.",
    attachments: [{ name: "Agreement.pdf", link: "#" }],
    avatar: "/assets/images/john-chat.png",
  },
  {
    sender: "Jenny HR Manager",
    text: "I have created a checklist for you.",
    attachments: [{ name: "Checklist.pdf", link: "#" }],
    avatar: "/assets/images/hr-manager-chat.png",
  },
];

export default function HrAvatarMode() {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [files, setFiles] = useState([]);
  const [showChatScreen, setShowChatScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Select training area");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [interviewEnded, setInterviewEnded] = useState(false);
  const [aiStatus, setAiStatus] = useState('ready'); // 'thinking' or 'ready'
  const [language, setLanguage] = useState('english'); // 'english' or 'arabic'
  const [peerConnection, setPeerConnection] = useState(null);
  const [status, setStatus] = useState('empty');
  const [statsIntervalId, setStatsIntervalId] = useState(null);
  const [lastBytesReceived, setLastBytesReceived] = useState(0);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [interviewId, setInterviewId] = useState(null);
  const [streamId, setStreamId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [aiThinking, setAiThinking] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  
  const streamIdRef = useRef(null);
  const sessionIdRef = useRef(null);

  const videoElementRef = useRef(null);
  const peerConnectionRef = useRef(null);

  async function createPeerConnection(offer, iceServers) {
    let pc = new RTCPeerConnection({ iceServers });
    
    pc.addEventListener('icecandidate', onIceCandidate, true);
    pc.addEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
    pc.addEventListener('track', onTrack, true);
    
    console.log("Created new peer connection", pc);
    
    // Store in both state and ref
    setPeerConnection(pc);
    peerConnectionRef.current = pc;
  
    await pc.setRemoteDescription(offer);
    const sessionClientAnswer = await pc.createAnswer();
    await pc.setLocalDescription(sessionClientAnswer);
  
    let dc = await pc.createDataChannel("JanusDataChannel");
    dc.onopen = () => {};
  
    let decodedMsg;
    dc.onmessage = (event) => {
      let msg = event.data;
      let msgType = "chat/answer:";
      if (msg.includes(msgType)) {
        msg = decodeURIComponent(msg.replace(msgType, ""));
        decodedMsg = msg;
        return decodedMsg;
      }
    };
  
    dc.onclose = () => {};
    return sessionClientAnswer;
  }
  
  
  function onIceCandidate(event) {
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      if (!streamIdRef.current) {
        console.error('Stream ID not found:', streamIdRef.current);
        return;
      }
      if (!sessionIdRef.current) {
        console.error('Session ID not found:', sessionIdRef.current);
        return;
      }
      fetch(`${DID_API.url}/${DID_API.service}/streams/${streamIdRef.current}/ice`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionIdRef.current,
        }),
      });
    }
  }
  
  function onIceConnectionStateChange() {
    if (peerConnectionRef.current?.iceConnectionState === 'failed' || peerConnectionRef.current?.iceConnectionState === 'closed') {
      stopAllStreams();
      closePC();
    }
  }

  function stopAllStreams() {
    if (videoElementRef.current?.srcObject) {
      videoElementRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoElementRef.current.srcObject = null;
    }
  }

  function onTrack(event) {
    console.log("ONTRACK EVENT:", event);
    console.log("Track type:", event.track.kind);
    console.log("Streams:", event.streams);
    
    if (!event.track) return;
  
    // if (event.track.kind === 'video') {
    //   console.log("Video track received:", event.track);
    //   if (event.streams && event.streams[0]) {
    //     console.log("Setting video stream immediately");
    //     setVideoElement(event.streams[0]);
    //   }
    // }
    if (statsIntervalId) {
      console.log("IT IS",statsIntervalId)
    }
    const newStatsIntervalId = setInterval(async () => {
      if (interviewEnded) {
        clearInterval(newStatsIntervalId);
        return;
      }
  
      // Use the ref instead of state
      if (!peerConnectionRef.current) {
        console.log("No peer connection ref, clearing interval");
        clearInterval(newStatsIntervalId);
        return;
      }
  
      try {
        const stats = await peerConnectionRef.current.getStats(event.track);
        stats.forEach((report) => {
          if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
            console.log("Video stats:", report);
            const videoStatusChanged = videoIsPlaying !== report.bytesReceived > lastBytesReceived;
  
            if (videoStatusChanged) {
              console.log("Video status changed:", {
                isPlaying: report.bytesReceived > lastBytesReceived,
                bytesReceived: report.bytesReceived,
                lastBytesReceived: lastBytesReceived
              });
              setVideoIsPlaying(report.bytesReceived > lastBytesReceived);
              onVideoStatusChange(report.bytesReceived > lastBytesReceived, event.streams[0]);
            }
            setLastBytesReceived(report.bytesReceived);
          }
        });
      } catch (error) {
        console.error('Error getting stats:', error);
        clearInterval(newStatsIntervalId);
      }
    }, 500);
  
    setStatsIntervalId(newStatsIntervalId);
  }

  const setVideoElement = (stream) => {
    console.log("Setting video element with stream:", stream);
    if (!stream || !videoElementRef.current) {
      console.log("Missing stream or video element:", { stream, videoElement: videoElementRef.current });
      return;
    }
    
    try {
      // Add animation class
      //videoElementRef.current.classList.add("animated");
      
      // Configure video element
      videoElementRef.current.muted = false;
      videoElementRef.current.srcObject = stream;
      videoElementRef.current.loop = false;
  
      console.log("Video element configured:", {
        muted: videoElementRef.current.muted,
        srcObject: videoElementRef.current.srcObject,
        loop: videoElementRef.current.loop
      });
  
      // Remove animation class after completion
      // setTimeout(() => {
      //   if (videoElementRef.current) {
      //     videoElementRef.current.classList.remove("animated");
      //   }
      // }, 1000);
  
      // Safari hotfix
      // if (videoElementRef.current.paused) {
      //   console.log("Video is paused, attempting to play");
      //   videoElementRef.current.play()
      //     .then(() => {
      //       console.log("Video playing successfully");
      //     })
      //     .catch(e => {
      //       console.error('Error playing video:', e);
      //     });
      // }
    } catch (error) {
      console.error("Error setting video element:", error);
    }
  };
    
  function closePC(pc = peerConnectionRef.current) {
    if (!pc) return;
    pc.close();
    pc.removeEventListener('icecandidate', onIceCandidate, true);
    pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
    pc.removeEventListener('track', onTrack, true);
    clearInterval(statsIntervalId);
    setPeerConnection(null);
    peerConnectionRef.current = null;
  }
  
  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (statsIntervalId) {
        clearInterval(statsIntervalId);
      }
      if (peerConnection) {
        closePC(peerConnection);
      }
    };
  }, []);
  
  function onVideoStatusChange(isPlaying, stream) {
    console.log("ON VIDEO STATUS CHANGE", isPlaying, stream)
    if (isPlaying) {
      setStatus('streaming');

      const remoteStream = stream;
      setVideoElement(remoteStream);
    } else {
      if (interviewEnded) {
        // handleEndInterview();
      } else {
        setStatus('empty');
        playIdleVideo();
      }
    }
  }

  // Modify your existing handleAudioToggle to handle stream muting
  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => {
      const newState = !prevState;
      const videoElement = videoElementRef.current;
      if (videoElement && videoElement.srcObject) {
        videoElement.srcObject.getAudioTracks().forEach(track => {
          track.enabled = newState;
        });
      }
      return newState;
    });
  };

  // Add cleanup useEffect
  useEffect(() => {
    return () => {
      // Change this
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
      // Change this
      const videoElement = document.querySelector('.right-img-wrap .caller-img');
      // To this
      if (videoElementRef.current?.srcObject) {
        videoElementRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);
  const playIdleVideo = () => {
    if (!videoElementRef.current) return;
    
    videoElementRef.current.classList.add("animated");
    videoElementRef.current.srcObject = undefined;
    videoElementRef.current.src = '/assets/videos/emma_idle.mp4';
    videoElementRef.current.loop = true;
  
    setTimeout(() => {
      if (videoElementRef.current) {
        videoElementRef.current.classList.remove("animated");
      }
    }, 1000);
  };
  
  // Modify your handleStartClick function to this exact code
  const handleStartClick = async () => {
    try {
      setIsConnecting(true); // Start loading state

      // Start D-ID stream first
      const streamResponse = await fetch(`${DID_API.url}/talks/streams`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source_url: 'https://create-images-results.d-id.com/DefaultPresenters/Emma_f/v1_image.jpeg'
        }),
      });
  
      const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await streamResponse.json();
  
      // Update stream info state
      setStreamId(newStreamId);
      setSessionId(newSessionId);
      streamIdRef.current = newStreamId;
      sessionIdRef.current = newSessionId;
      
  
      // Create peer connection and get answer
      const answer = await createPeerConnection(offer, iceServers);
  
      // Send SDP answer
      const sdpResponse = await fetch(`${DID_API.url}/talks/streams/${newStreamId}/sdp`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answer,
          session_id: newSessionId
        }),
      });
  
      const { session_id: updatedSessionId } = await sdpResponse.json();

      // Initialize backend session
      const sessionResponse = await fetch('http://localhost:3030/hr/session/new', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      });
  
      const sresp = await sessionResponse.json();
      console.log("SESSION RESPONSE", sresp);
      setInterviewId(sresp.interviewId);
  
      setIsConnected(true);

      // Start with idle video
      if (videoElementRef.current) {
        playIdleVideo();
      }
  
    } catch (error) {
      console.error('Error starting stream:', error);
      // Optionally handle error state here
      setAiStatus('error');
    } finally {

    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const inputRef = useRef(null);

  const handleIconClick = () => {
    inputRef.current.click();
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };

  const renderfilesDropBar = () => {
    return (
      <div className="desktop-footer-wrap">
        <div className="drop-file-wrap">
          <div
            {...getRootProps()}
            style={{
              border: isDragActive ? "" : "2px dashed #ccc",
              textAlign: "center",
              cursor: "pointer",
              position: isDragActive ? "fixed" : "relative",
              top: isDragActive ? 0 : "auto",
              left: isDragActive ? 0 : "auto",
              width: isDragActive ? "100vw" : "auto",
              height: isDragActive ? "100vh" : "auto",
              backgroundColor: isDragActive ? "#241534E8" : "transparent",
              zIndex: isDragActive ? 1000 : "auto",
              display: isDragActive ? "flex" : "block",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="drop-file-title">
                <img src="/assets/images/drop-file-icon.png" alt=""/>
                Drop file here to upload</p>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <img src="/assets/images/Arrow Upload.svg" alt="" />
                <div className="dropFileText">
                  Drop files here to help Jenny assist you
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="chat-button-wrap cursor-pointer"
          onClick={() => setShowChatScreen(true)}
        >
          <img src="/assets/images/Chat.svg" alt="" />
          <div className="message-count">2</div>
        </div>
      </div>
    );
  };

  // Add this state at the top of your component
  const [messageText, setMessageText] = useState('');

  // Add this function to your component
  const sendMessage = async (msgText) => {
    if (interviewEnded) {
      return;
    }

    // Check peer connection state
    if (peerConnectionRef.current?.signalingState === 'stable' || peerConnectionRef.current?.iceConnectionState === 'connected') {
      if (msgText.length < 2) {
        console.error('Ignoring blank msg: ', msgText);
        return;
      }

      // Show AI is thinking
      setAiThinking(true);

      console.log("GONNA SEND", { 
        msgText,
        streamId: streamId,
        sessionId: sessionId,
        interviewId: interviewId,
      });

      try {
        const response = await fetch('http://localhost:3030/hr/session/user_msg', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            msgText,
            streamId: streamId,
            sessionId: sessionId,
            interviewId: interviewId,
          }),
        });

        const result = await response.json();
        
        if (result.endInterview) {
          setInterviewEnded(true);
        }

        // Handle any required info or file URLs from response
        if (result.requiredInfo) {
          // Handle required info (maybe set in state)
          // setRequiredInfo(result.requiredInfo);
        }
        
        if (result.fileURL) {
          // Handle file URL (maybe set in state)
          // setFileURL(result.fileURL);
        }

      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        //setAiThinking(false);
      }
    }
  };

  return (
    <div className="trainer-mode-wrapper hr-avatar-page-wrap">
      <Helmet>
        <title>MPLOI: Abu Dhabi Legal Assist</title>
      </Helmet>
      <div className="trainer-mode-header">
        <div className="header-wrap">
          <div className="header-left-wrap">
            <img
              src="/assets/images/mploi-logo-white.svg"
              alt="MPLOI Logo"
              className="logo"
            />
            <div className="title">
              {isConnected ? "Connected" : "Awaiting start"}
              <img src="/assets/images/waveform.png" alt="waveform Icon" />
            </div>
          </div>
          <div className="header-right-wrap">
          <button 
            className="green-trainer-button" 
            onClick={handleStartClick}
            disabled={isConnecting}
          >
            {isConnecting ? (
              <>
                <span className="spinner"></span>
                Connecting...
              </>
            ) : (
              <>
                Start discussion{" "}
                <img src="/assets/images/person-voice-2.png" alt="Icon" />
              </>
            )}
          </button>
          </div>
        </div>
      </div>

      <div className="trainer-details">
        <div className="caller-details">
          <div>
            <span>Abu Dhabi: </span> Corporate Legal
          </div>
          <div className="dot"> • </div>
          <div>
            <span>Talking with:</span> Jenny, MPLOI Legal
          </div>
        </div>
      </div>
      {isConnected && (
      <>
        <div className="trainer-mode-wrap">
          <div className="trainer-mode-top-content">
            <div className="status">
              <img src="/assets/images/person-voice.png" alt="" /> Try saying,
              “good morning”
            </div>
            <button className="start-trainer-button">
              End call{" "}
              <img src="/assets/images/call-end-icon.png" alt="Icon" />
            </button>
          </div>
          <div className="trainer-mode-bottom-content">
            <div
              className={
                "left-img-wrap " +
                `${showChatScreen ? "left-content-resize" : ""}`
              }
            >
              <div className="top-title-mob">
                <img src="/assets/images/person-voice.png" alt="" /> Try saying,
                “good morning”
                <span>
                  {" "}
                  <img src="/assets/images/wave-color.png" alt="" />
                </span>
              </div>
              <img
                className="caller-img"
                src="/assets/images/caller-1.png"
                alt="You"
              />
              <div className="top-img-mic-icon">
                <img src="/assets/images/audio-rang.png" alt="" />{" "}
              </div>
              <div className="bottom-icon-wrap">
                <div className="bottom-title">
                  You
                </div>
                <div className="feature-icon-wrap">
                  <button onClick={handleAudioToggle} className="icon-button">
                    <img
                      src={
                        isAudioActive
                          ? "/assets/images/audio-call-icon.png"
                          : "/assets/images/audio-call-off-icon.png"
                      }
                      alt="Audio"
                      className="icon-img"
                    />
                  </button>
                  <button onClick={handleCameraToggle} className="icon-button">
                    <img
                      src={
                        isCameraActive
                          ? "/assets/images/video-call-icon.png"
                          : "/assets/images/vdo-call-off-icon.png"
                      }
                      alt="Camera"
                      className="icon-img"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={
              "right-img-wrap " + (showChatScreen ? "right-content-resize" : "")
            }>
              {/* Status indicator */}
              {aiStatus === 'thinking' ? (
                <div className="thinking-btn">
                  <img src="/assets/images/thinking.png" alt="" />
                  <span>Thinking..</span>
                </div>
              ) : (
                <div className="ready-btn">
                  <img src="/assets/images/ready.png" alt="" />
                  <span>Ready</span>
                </div>
              )}

              {/* Language selector */}
              <div className="language-selector">
                <button 
                  className={`language-btn ${language === 'english' ? 'active' : ''}`}
                  onClick={() => setLanguage('english')}
                >
                  English
                </button>
                <button 
                  className={`language-btn ${language === 'arabic' ? 'active' : ''}`}
                  onClick={() => setLanguage('arabic')}
                >
                  عربي
                </button>
              </div>

              <video
                ref={videoElementRef}
                className="caller-img"
                autoPlay
                playsInline
                loop
                muted={!isAudioActive}
                src="/assets/videos/emma_idle.mp4" // Default idle video
              />
              <div className="bottom-title">
                Assistant: <span>Jenny, MPLOI HR Assistant</span>
              </div>
            </div>
            {showChatScreen ? (
              <div className="desktop-chat">
                <ChatMessages
                  chatData={chatData}
                  closeChat={() => setShowChatScreen(false)}
                  onSendMessage={sendMessage}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="trainer-mode-bottom-wrap">
          <div className="attach-file cursor-pointer" onClick={handleIconClick}>
              <input type="file" style={{ display: "none" }} ref={inputRef} />
              <img src="/assets/images/gallery-icon-mob.png" alt=" " />
            </div>
          
          <button onClick={handleAudioToggle} className="icon-button">
                    <img
                      src={
                        isAudioActive
                          ? "/assets/images/audio-call-icon.png"
                          : "/assets/images/audio-call-off-icon.png"
                      }
                      alt="Audio"
                      className="icon-img"
                    />
                  </button>
            <img src="/assets/images/call-cut.png" className="mid-icn" alt=" " />
            <button onClick={handleCameraToggle} className="icon-button">
                    <img
                      src={
                        isCameraActive
                          ? "/assets/images/video-call-icon.png"
                          : "/assets/images/vdo-call-off-icon.png"
                      }
                      alt="Camera"
                      className="icon-img"
                    />
                  </button>
            <img src="/assets/images/chat-icon-mob.png"  onClick={() => setOpenDrawer(true)} alt=" " />
          </div>
          <div>{renderfilesDropBar()}</div>
        </div>
      </>)}

        <MobileDrawer
          open={openDrawer}
          toggleDrawer={toggleDrawer}
          customClass="mobile-chat-wrap"
          drawerData={
            <div className="chat-mob-screen"><ChatMessages
            chatData={chatData}
            closeChat={() => setShowChatScreen(false)}
          /></div>
          }

      />
    </div>
  );
}

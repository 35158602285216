import { api } from "../middleware/api";
import { END_POINTS } from "../utils/constants/endPoints";

export const userService = {
  login,
  logout,
  signup,
  forgotPassword,
  resetPassword,
  legalContent,
  userProfile,
  getUserData,
  resendMail,
  verifyOtp,
  sendOtp,
  // fetchAllProducts,
  changePassword,
  filter_jobs,
  getAllMploiees,
  getActiveConversation,
  getPendingRequest,
  setJobAllocated,
  showEmployeeSidebar,
  // showTransactionHistory,
  deleteJob,
  deleteAccount,
  fetchAllJobs,
  contactAdmin,
  fetchCourseList,
  fetchSchoolList,
  fetchTransactionHistory,
  fetchChoseSubscription,
  fetchYearSubscription,
  fetchMonthSubscription,
  evaluatePhoto,
  aiRequest,
  userResume,
};
// let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzAwMzYzMDIzLCJpYXQiOjE2OTc3NzEwMjMsImp0aSI6ImQ0NmU0Y2RkMTZjMzQyOWE5ZjcwNmNlMzU2NTY1NGMwIiwidXNlcl9pZCI6IjI3YTQ1Yjg0LWI2NTEtNGNhNy1iMDU4LWIyNWQyMmZhMmRhMSJ9.GeNo_Jfn298iAfgVQVaYGJOeHvk44LewVKaD_itkMM4'
// const headers = {
//   Authorization: `Bearer ${token}` 
// }

function login(params) {
  return api.post(END_POINTS.login, params);
}

function signup(params, referral_code, isAccountsSignup) {
  params.isAccountsSignup = isAccountsSignup;
  return api.post(END_POINTS.signup + referral_code, params);
}

function logout() {
  return api.post(END_POINTS.logout, null);
}
function forgotPassword(params) {
  return api.post(END_POINTS.forgotPassword, params);
}

function resetPassword(params) {
  return api.post(END_POINTS.resetPassword, params);
}
function legalContent() {
  return api.get(END_POINTS.legalContent, null);
}

function userResume(data, query_params = "") {
  return api.post(END_POINTS.userResume + query_params, data);
}

function userProfile(data, query_params = "") {
  return api.patch(END_POINTS.user + query_params, data);
}
function evaluatePhoto(data) {
  return api.post(END_POINTS.evaluatePhoto, data);
}
function aiRequest(data, hideLoading=false) {
  const endpoint = hideLoading ? END_POINTS.aiRequest + "?hideLoading=true" : END_POINTS.aiRequest;
  return api.post(endpoint, data);
}
function getUserData() {
  return api.get(END_POINTS.profile, null);
}
function resendMail() {
  return api.get(END_POINTS.resend, null);
}
function verifyOtp(data) {
  return api.put(END_POINTS.verifyOtp, data);
}
function sendOtp() {
  return api.get(END_POINTS.sendOtp, null);
}

// function fetchAllProducts() {
//   return api.get(END_POINTS.fetchStripeProducts, null);
// }

function changePassword(data) {
  return api.post(END_POINTS.changePassword, data);
}
function filter_jobs(data) {
  return api.post(END_POINTS.filter_jobs, data);
}
function getAllMploiees(jobId) {
  return api.get(END_POINTS.getAllMploiees + jobId, null);
}
function getActiveConversation(jobId) {
  return api.get(END_POINTS.getActiveConversation + jobId, null);
}
function getPendingRequest(jobId) {
  return api.get(END_POINTS.getPendingRequest + jobId, null);
}
function setJobAllocated(jobId, data) {
  return api.patch(jobId + "/job/", data);
}
function showEmployeeSidebar(employeeId, jobId) {
  return api.get(employeeId + "/employee-employer/?jobId=" + jobId, null);
}
// function showTransactionHistory() {
//   return api.get(END_POINTS.transactionHistory, null);
// }
function deleteJob(selectedJobId) {
  return api.deleteApi(selectedJobId + "/job/", null);
}
function deleteAccount() {
  return api.deleteApi(END_POINTS.profile, null);
}
function contactAdmin() {
  return api.get(END_POINTS.contactAdmin, null);
}

function fetchCourseList() {
  return api.get(END_POINTS.courseList, null);
}

function fetchSchoolList() {
  return api.get(END_POINTS.schoolList, null);
}
function fetchChoseSubscription() {
  return api.get(END_POINTS.chose_subscription, null);
}
function fetchYearSubscription() {
  return api.get(END_POINTS.year_subscriptions, null);
}
function fetchMonthSubscription() {
  return api.get(END_POINTS.month_subscriptions, null);
}

function fetchTransactionHistory() {
  return api.get(END_POINTS.subscritionTransactionHistory, null);
}

function fetchAllJobs(baseURL, params, successCB) {
  const from = params?.filter?.created_at__date__gte;
  const to = params?.filter?.created_at__date__lte;
  const sort = params?.sort;
  const search = params?.search;
  let range;
  if (from || to) {
    if (from && !to) {
      range = { filter: { created_at__date__gte: from } };
    } else if (!from && to) {
      range = { filter: { created_at__date__lte: to } };
    } else if (from && to) {
      range = {
        filter: { created_at__date__gte: from, created_at__date__lte: to },
      };
    }
  }
  let API = "";
  if (params?.onlyForJob) {
    API = `${baseURL}&search=${search}`;
  } else {
    API = `${baseURL}${sort ? "" : "?sort=created_at"}${sort == true
      ? search == ""
        ? ""
        : `?search=${search}`
      : search == ""
        ? ""
        : `&search=${search}`
      }`;
  }
  api
    .post(API, range || null)
    .then((res) => {
      successCB?.(res);
      //return res;
    })
    .catch((e) => { });
}

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { get } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../store";
import { bindActionCreators } from "redux";

import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { cookieService, userService } from "../../_services";
import Logout from "../../components/logout";
import ValidationErrorToast from "../../components/validationErrorToast";
import ToastHeader from "../../components/toastHeader";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { Helmet } from 'react-helmet';


const VerifyMobile = (props: any) => {
  const [show, setShow] = useState(false);
  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const { setSignUpMobileNumber } = bindActionCreators(actionCreators, dispatch);
  const [phone, setPhone] = useState<any>();

  useEffect(() => {
    let number = state.user.mobileNumber || get(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user), 'user.mobileNumber', null)
    if (number) {
      // let numberWithoutCode = getNumberFromMobileNumber(number);
      setPhone(number);
    }
  }, [])

  const handleSubmit = () => {
    if (UtilityFunctions.isEmptyOrNullOrUndefined(phone)) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_number, STRING_CONSTANTS.validationString.req_mobileNumber);
    }
    else {
      const params = {
        user: {
          mobileNumber: phone,
        },
      };
      userService.userProfile(params)
        .then((res) => {
          userService.sendOtp()
            .then(res => { props.setOtp((prev) => { return { ...prev, token: res?.["token"] } }) })
          setSignUpMobileNumber(phone);
          props.nextButton()
        })
        .catch((err) => {
          ToastHeader(err);
          return err;
        });
    }
  }

  const onBoarding = {
    pathname: "/",
    state: { activeIndex: 2 }
  }

  return (
    <>
      <Helmet>
        <title>MPLOI - Verify Mobile</title>
      </Helmet>
      <div className="row">
        <div className="col-md-3 col-lg-4 col-xl-4 col-12">
          <div className="left-wrap">
            <div className="logo-wrap">
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                onClick={() => props.history.push(onBoarding)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4 col-12">
          <div className="middle-wrap button-full-heign">
            <div className="mysetups">


              <div className='top-divde-spa'>
                <span>
                  <i
                    className="text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => setShow(true)}
                  >
                    <img src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"} width={'22px'} height={'22px'} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z" fill="white" />
                    </svg> */}
                  </i>
                </span>
                <div className="step-content signup-mar">
                  <button type='button' disabled>
                    {STRING_CONSTANTS.signupPages.verifyMobile.verify_mobile}
                  </button>
                  <p>{STRING_CONSTANTS.signupPages.verifyMobile.mobile_otp}</p>
                  <div className="step-text">
                    {STRING_CONSTANTS.signupPages.verifyMobile.step}
                  </div>
                </div>

              </div>
              <div className="form-wrap text-start">
                <div className='v-form-width'>
                  <div className="input-group mb-3">
                    <PhoneInput
                      country={'au'}
                      placeholder="00 000 000"
                      countryCodeEditable={false}
                      value={phone}
                      masks={{ au: '... ... ....', in: '...........' }}
                      onChange={phone => setPhone('+' + phone)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="next-wrap">
              <button
                className={phone?.length > 10 ? "cricle-next" : "cricle-next-inactive"}
                onClick={() => handleSubmit()}

              >
                <img
                  alt=""
                  src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Logout
        show={show}
        handleClose={() => setShow(false)}
      />
    </>
  );
};
export default withRouter(VerifyMobile);

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api } from '../../middleware/api';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import moment from 'moment';


const styles = {
    topBarTitle: {
        color: '#ffffff',
        margin: 0,
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonGroup: {
        display: 'flex',
        gap: '12px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        fontWeight: '500',
    },
    primaryButton: {
        backgroundColor: '#007bff',
        color: '#ffffff',
    },
    secondaryButton: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: '#ffffff',
    },
    tilesContainer: {
        display: 'flex',
        gap: '24px',
        padding: '48px',
        justifyContent: 'center',
        alignItems: 'stretch',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    tile: {
        flex: 1,
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '12px',
        padding: '48px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    tileIcon: {
        width: '120px',
        height: '120px',
        marginBottom: '24px',
        opacity: '0.8',
    },
    tileTitle: {
        fontSize: '24px',
        fontWeight: '500',
        marginBottom: '16px',
        color: '#ffffff',
    },
    tileText: {
        color: 'rgba(255, 255, 255, 0.6)',
        marginBottom: '24px',
        maxWidth: '400px',
        lineHeight: '1.6',
    },
    connectButton: {
        padding: '12px 24px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        backgroundColor: '#007bff',
        color: '#ffffff',
        transition: 'background-color 0.2s',
        width: '200px',
        // Remove the &:hover
    },
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    topBar: {
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    statsCard: {
        display: 'flex',
        alignItems: 'center',
    },
    statsContent: {
        marginLeft: '15px',
    },
    table: {
        width: '100%',
        color: '#fff',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    tableHeader: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: '500',
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'left',
    },
    tableCell: {
        padding: '15px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        color: '#fff',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '48px',
        textAlign: 'center',
        color: '#ffffff',
    }
};


const AccountsReceivable = (props) => {
    const dispatch = useDispatch();
    // Add this line to get the state from Redux
    const state = useSelector((state: State) => state);
    const [userData, setUserData] = useState(null);
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [xeroConnected, setXeroConnected] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    const [initialLoading, setInitialLoading] = useState(true);
    const [stats, setStats] = useState({
        callsToday: 0,
        totalOutstanding: 0,
        successfulCollections: 0,
        totalCollected: 0
    });

    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount)
                    setUserData(res)
                })
        }
    }, []);

    const initiateCall = async (organizationId, invoiceId = null) => {
        try {
            SuccessToast("Please wait", "Initiating call...");

            const response = await userService.aiRequest({
                method: 'initiate-call',
                data: {
                    id: user.id,
                    org_name: user.businessName,
                    invoiceId,
                }
            }, true);

            console.log('!!!!Initiate call response:', response);

            if (response.data?.success) {
                SuccessToast("Success", "Call successfully initiated.");
            } else {
                ValidationErrorToast("Error", `Call could not be initiated: ${response.data?.error || 'Unknown error'}`);
            }
        } catch (error) {
            ValidationErrorToast("Error", `Call could not be initiated: ${error.message || 'Unknown error'}`);
        }
    };

    const handleXeroConnection = async () => {
        try {
            setLoading(true);
            // Get the current user's account ID from your user data
            const account_id = user.id; // Adjust this based on your user data structure
            
            // Redirect to your Xero connection endpoint with the account_id
            // @TODO: prod handle this....
            //window.location.href = `http://localhost:5000/connect/${account_id}`;
            window.location.href = `http://172.210.80.250/connect/${account_id}`;
            
        } catch (error) {
            ValidationErrorToast("Error", "Failed to connect to Xero");
            setLoading(false);
        }
    };

    const handleMyobConnection = () => {
        // Placeholder for MYOB connection
        ValidationErrorToast("Coming Soon", "MYOB integration will be available soon!");
    };

    // State declarations
    const [invoiceData, setInvoiceData] = useState({
        invoices: [],
        summary: {
            total_invoices: 0,
            overdue_summary: {
                overdue_7_days: 0,
                overdue_14_days: 0,
                overdue_30_days: 0,
                overdue_60_days: 0,
                overdue_90_days: 0
            },
            call_summary: {
                total_calls: 0,
                invoices_with_payment_plans: 0,
                invoices_needing_followup: 0
            },
        }
    });

    // useEffect to check connection and load data
    useEffect(() => {
        const loadXeroData = async () => {
            try {
                const account_id = user.id;
                // Check Xero connection
                const orgResponse = await userService.aiRequest({
                    data: { account_id }, 
                    method: 'check-xero-connection'
                });

                if (orgResponse.data && orgResponse.data.organizations && orgResponse.data.organizations.length > 0) {
                    setXeroConnected(true);
                    setLoading(true);  // Only set loading for data fetch
                    
                    // Get invoice data
                    const invoiceResponse = await userService.aiRequest({
                        data: { account_id }, 
                        method: 'get-account-invoices'
                    });
        
                    if (invoiceResponse.data) {
                        // Set invoice data
                        setInvoiceData(invoiceResponse.data);
                        
                        // Update stats from the stats summary
                        setStats({
                            callsToday: invoiceResponse.data.summary.stats.callsToday,
                            totalOutstanding: invoiceResponse.data.summary.stats.totalOutstanding,
                            successfulCollections: invoiceResponse.data.summary.stats.successRate,
                            totalCollected: invoiceResponse.data.summary.stats.totalCollected
                        });
        
                        // Update accounts list
                        const accounts = invoiceResponse.data.invoices.map(invoice => ({
                            id: invoice.invoiceID,
                            organizationId: invoice.organizationId,
                            customerName: invoice.contact?.name || 'Unknown Customer',
                            invoiceNumber: invoice.invoiceNumber,
                            amount: parseFloat(invoice.amountDue || 0),
                            dueDate: invoice.overdueFlags.dueDate,
                            status: invoice.Status,
                            email: invoice.contact?.emailAddress || 'No email provided',
                            phone: invoice.contact?.phones?.[0]?.phoneNumber || 'No phone provided',
                            lastContact: invoice.lastContact || null,
                            overdue_flags: invoice.overdueFlags || {
                                daysOverdue: 0,
                                needsToFollowup: true
                            },
                            call_history: invoice.call_history || {
                                calls: [],
                                totalCalls: 0,
                                hasPaymentPlan: false,
                                latestOutcome: null
                            }
                        }));
        
                        setAccounts(accounts);
                    }
                } else {
                    setXeroConnected(false);
                }
            } catch (error) {
                console.error('Error loading Xero data:', error);
                ValidationErrorToast("Error", "Failed to load Xero data");
                setXeroConnected(false);
            } finally {
                setInitialLoading(false);  // Always set initial loading to false
                setLoading(false);
            }
        };

        if (userData) {
            loadXeroData();
        }
    }, [userData]);

    const EmptyState = () => (
        <div style={styles.tilesContainer}>
            {/* Xero Tile */}
            <div style={styles.tile}>
                <img 
                    src={UtilityFunctions.getImageURL() + "xero.png"} 
                    alt="Connect Xero"
                    style={styles.tileIcon}
                />
                <h2 style={styles.tileTitle}>Connect Your Xero Account</h2>
                <p style={styles.tileText}>
                    Connect your Xero account to start managing your accounts receivable. 
                    Track invoices, manage collections, and automate follow-ups.
                </p>
                <button 
                    style={styles.connectButton}
                    disabled={loading}
                    onClick={handleXeroConnection}
                >
                    {loading ? "Connecting..." : "Connect Xero"}
                </button>
            </div>

            {/* MYOB Tile */}
            <div style={styles.tile}>
                <img 
                    src={UtilityFunctions.getImageURL() + "myob.png"} 
                    alt="Connect MYOB"
                    style={styles.tileIcon}
                />
                <h2 style={styles.tileTitle}>Connect Your MYOB Account</h2>
                <p style={styles.tileText}>
                    Link your MYOB account to streamline your accounts receivable process.
                    Manage invoices, track payments, and automate collections.
                </p>
                <button 
                    style={{...styles.connectButton, backgroundColor: '#6c757d'}}
                    onClick={handleMyobConnection}
                >
                    Coming Soon
                </button>
            </div>
        </div>
    );

    const Dashboard = () => (
        <div className="row">
            {loading ? (
                <div style={{...styles.emptyState, color: '#fff'}}>
                    Loading Xero data...
                </div>
            ) : (
                <>
                    <div className="col-xl-12">
                        <div className="row mb-4">
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        {/* <div style={styles.iconBox}>
                                            <img src={UtilityFunctions.getImageURL() + "phone-icon.svg"} alt="" />
                                        </div> */}
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Calls Today</h4>
                                            <h2 style={styles.whiteText}>{stats.callsToday}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        {/* <div style={styles.iconBox}>
                                            <img src={UtilityFunctions.getImageURL() + "money-icon.svg"} alt="" />
                                        </div> */}
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Outstanding</h4>
                                            <h2 style={styles.whiteText}>${stats.totalOutstanding}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        <div style={styles.iconBox}>
                                            <img src={UtilityFunctions.getImageURL() + "success-icon.svg"} alt="" />
                                        </div>
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Success Rate</h4>
                                            <h2 style={styles.whiteText}>{stats.successfulCollections}%</h2>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        {/* <div style={styles.iconBox}>
                                            <img src={UtilityFunctions.getImageURL() + "collected-icon.svg"} alt="" />
                                        </div> */}
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Total Collected</h4>
                                            <h2 style={styles.whiteText}>${stats.totalCollected}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div style={styles.blueBox}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                                <h3 style={styles.whiteText}>Outstanding Invoices</h3>
                                <div style={styles.mutedText}>
                                    Invoices 30+ days overdue
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table style={styles.table}>
                                    <thead>
                                        <tr>
                                            <th style={styles.tableHeader}>Customer</th>
                                            <th style={styles.tableHeader}>Invoice #</th>
                                            <th style={styles.tableHeader}>Amount</th>
                                            <th style={styles.tableHeader}>Due Date</th>
                                            <th style={styles.tableHeader}>Status</th>
                                            <th style={styles.tableHeader}>Last Contact</th>
                                            <th style={styles.tableHeader}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accounts.map(account => (
                                            <tr key={account.id}>
                                                <td style={styles.tableCell}>
                                                    <div>
                                                        <div style={styles.whiteText}>{account.customerName}</div>
                                                    </div>
                                                </td>
                                                <td style={styles.tableCell}>{account.invoiceNumber}</td>
                                                <td style={styles.tableCell}>${account.amount.toLocaleString()}</td>
                                                <td style={styles.tableCell}>
                                                    {moment(account.dueDate).format('DD/MM/YYYY')}
                                                </td>
                                                <td style={styles.tableCell}>
                                                    <span style={{
                                                        ...styles.badge,
                                                        backgroundColor: account.overdue_flags?.daysOverdue > 90 ? '#dc3545' :
                                                                       account.overdue_flags?.daysOverdue > 30 ? '#ffc107' :
                                                                       account.overdue_flags?.daysOverdue > 0 ? '#17a2b8' : 
                                                                       '#28a745'
                                                    }}>
                                                        {account.overdue_flags?.daysOverdue > 0 ? 
                                                            `${account.overdue_flags?.daysOverdue} days` : 
                                                            'Current'}
                                                    </span>
                                                </td>
                                                <td style={styles.tableCell}>
                                                    {account.lastContact ? 
                                                        moment(account.lastContact).format('DD/MM/YYYY HH:mm') : 
                                                        'Never'}
                                                </td>
                                                <td style={styles.tableCell}>
                                                    <div style={{ display: 'flex', gap: '8px' }}>
                                                        <button 
                                                            style={{...styles.button, ...styles.primaryButton}}
                                                            onClick={() => initiateCall(account.organizationId)}
                                                        >
                                                            Call
                                                        </button>
                                                        <button 
                                                            style={{...styles.button, ...styles.secondaryButton}}
                                                            onClick={() => props.history.push(`/ar/view/${account.organizationId}`)}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Accounts Receivable</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} />
                        </Link>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.chat) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </span>
                        </li>
    
                        {user?.user?.type === STRING_CONSTANTS.userType.employer && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/modules">
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                            </Link>
                        </li>}
                    </div>
                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>
                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>
    
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        {/* Top Bar */}
                        <div style={styles.topBar}>
                            <h1 style={styles.topBarTitle}>Accounts Receivable</h1>
                            <div style={styles.buttonGroup}>
                                <button 
                                    style={{...styles.button, ...styles.secondaryButton}}
                                    onClick={() => props.history.push('/ar/settings')}
                                >
                                    Settings
                                </button>
                                {/* {xeroConnected && (
                                    <button 
                                        style={{...styles.button, ...styles.primaryButton}}
                                        onClick={() => {
                                            setXeroConnected(false);
                                            SuccessToast("Success", "Disconnected from Xero");
                                        }}
                                    >
                                        Disconnect Xero
                                    </button>
                                )} */}
                            </div>
                        </div>
    
                        {/* Main Content */}
                        <div className="container-fluid">
                            {initialLoading ? (
                                <div /> // Empty div while checking connection
                            ) : !xeroConnected ? (
                                <EmptyState />
                            ) : (
                                <Dashboard />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountsReceivable;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'; 
import { api } from '../../middleware/api';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import moment from 'moment';

const sampleInteractions = [
    {
        id: 1,
        type: 'call',
        direction: 'outbound',
        timestamp: '2024-01-15T14:30:00Z',
        duration: 325, // in seconds
        outcome: 'positive',
        summary: "Customer agreed to pay outstanding balance by end of month. Very cooperative and apologetic about delay.",
        recording_url: "https://example.com/recordings/call-1.mp3",
        transcript: "AI: Hello, this is AI calling from SimTheory regarding your outstanding invoice.\nCustomer: Yes, I'm aware of that. I can make the payment by the end of this month.\nAI: That's great to hear! Can you confirm you'll pay the full amount of $5,400?\nCustomer: Yes, I will transfer the full amount by January 31st.",
        customer_name: "John Smith",
        customer_email: "john@example.com",
        customer_phone: "+1234567890"
    },
    {
        id: 2,
        type: 'payment',
        timestamp: '2024-01-31T09:15:00Z',
        amount: 5400,
        method: 'Bank Transfer',
        reference: 'INV-2024-001',
        customer_name: "John Smith",
        customer_email: "john@example.com",
        customer_phone: "+1234567890"
    },
    {
        id: 3,
        type: 'call',
        direction: 'outbound',
        timestamp: '2024-02-05T11:20:00Z',
        duration: 180,
        outcome: 'neutral',
        summary: "Customer requested payment plan for new invoice. Needs to discuss internally.",
        recording_url: "https://example.com/recordings/call-2.mp3",
        transcript: "AI: Hello, I'm calling about the new invoice INV-2024-002.\nCustomer: Yes, we received it. We might need a payment plan this time.\nAI: I understand. What kind of payment schedule would work for you?\nCustomer: Let me discuss with our finance team and get back to you.",
        customer_name: "John Smith",
        customer_email: "john@example.com",
        customer_phone: "+1234567890"
    },
    {
        id: 4,
        type: 'call',
        direction: 'inbound',
        timestamp: '2024-02-10T15:45:00Z',
        duration: 240,
        outcome: 'positive',
        summary: "Customer called to set up payment plan. Agreed to 3 monthly installments.",
        recording_url: "https://example.com/recordings/call-3.mp3",
        transcript: "Customer: Hi, I'm calling about setting up that payment plan we discussed.\nAI: Yes, how can I help?\nCustomer: We'd like to split the payment into 3 monthly installments.\nAI: That works! Let me set that up for you right away.",
        customer_name: "John Smith",
        customer_email: "john@example.com",
        customer_phone: "+1234567890"
    }
];

// Styles object (extending from previous pages)
const styles = {
    backButton: {
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        }
    },
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    statsCard: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    iconBox: {
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '8px',
        padding: '12px',
        marginRight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeline: {
        position: 'relative',
        padding: '20px 0',
    },
    timelineItem: {
        position: 'relative',
        padding: '20px 0 20px 40px',
        borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
    },
    timelineDot: {
        position: 'absolute',
        left: '-6px',
        top: '24px',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
    button: {
        padding: '8px 16px',
        borderRadius: '4px',
        border: 'none',
        marginRight: '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#fff',
    },
    buttonPrimary: {
        backgroundColor: '#007bff',
    },
    buttonDanger: {
        backgroundColor: '#dc3545',
    },
    buttonSuccess: {
        backgroundColor: '#28a745',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    transcriptBox: {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        padding: '15px',
        marginTop: '10px',
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap',
        maxHeight: '200px',
        overflowY: 'auto',
        color: '#ffffff',  // Add this for white text
        lineHeight: '1.5', // Add this for better readability
        fontSize: '14px',  // Add this for better size
        border: '1px solid rgba(255, 255, 255, 0.1)', // Add subtle border
    },
    audioPlayer: {
        width: '100%',
        marginTop: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
    }
};

const CustomerProfile = (props) => {
    const { organizationId } = props.match.params;
    const dispatch = useDispatch();
    const [userData, setUserData] = useState<any>();
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    
    // Customer Data States
    const [customer, setCustomer] = useState({});
    const [interactions, setInteractions] = useState(sampleInteractions);
    const [loading, setLoading] = useState(true);
    const [activeTranscript, setActiveTranscript] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);

    // Stats
    const [stats, setStats] = useState({
        totalCalls: 0,
        successfulCalls: 0,
        totalDuration: 0,
        averageCallDuration: 0,
        totalCollected: 0,
        remainingAmount: 0,
        lastPaymentDate: null,
        nextScheduledCall: null
    });

    const state = useSelector((state: State) => state);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);

    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount)
                    setUserData(res)
                })
        }
    }, []);

    useEffect(() => {
        fetchCustomerData();
    }, [organizationId]);

    const fetchCustomerData = async () => {
        setLoading(true);
        const params = {
            'method': 'get-organization-data',
            data: {organizationId},
        }

        userService.aiRequest(params, true)
        .then(data => {
            setCustomer(data.data.customerInfo);
        })
        .catch(e => console.log(e))
    };

    useEffect(() => {
        const payments = interactions.filter(i => i.type === 'payment');
        const totalCollected = customer?.amountCollected || 0;
        const amountOwing = customer?.amountOwing || 0;
        const successfulCalls = customer?.calls || 0;

        setStats({
            totalCalls: successfulCalls,
            successfulCalls: successfulCalls,
            totalCollected: totalCollected,
            remainingAmount: amountOwing,
            lastPaymentDate: payments.length ? payments[0].timestamp : null,
            nextScheduledCall: null // Would be set from API
        });
    }, [customer]);


    useEffect(() => {
        const fetchInteractions = async () => {
            try {
                const response = await api.post('/api/', {
                    method: 'get-interactions',
                    data: { org_id: organizationId },
                });
                
                if (response.data.interactions) {
                    setInteractions(response.data.interactions.map(interaction => ({
                        id: interaction.id,
                        type: interaction.type,
                        direction: interaction.direction,
                        timestamp: interaction.timestamp,
                        duration: interaction.duration,
                        outcome: interaction.outcome,
                        summary: interaction.summary,
                        recordingUrl: interaction.recording_url,
                        transcript: interaction.transcript,
                        amount: interaction.amount,
                        method: interaction.method,
                        reference: interaction.reference,
                        customer: {
                            name: interaction.customer_name,
                            email: interaction.customer_email,
                            phone: interaction.customer_phone
                        }
                    })));
                }
            } catch (error) {
                console.error('Error fetching interactions:', error);
            }
        };
    
        fetchInteractions();
    }, [organizationId]);

    const initiateCall = async (customerName, invoiceId = null) => {
        try {
            SuccessToast("Please wait", "Initiating call...");
            
            const response = await userService.aiRequest({
                method: 'initiate-call',
                data: {
                    organizationId,
                    invoiceId,
                }
            }, true);

            console.log('!!!!Initiate call response:', response);
    
            if (response.data?.success) {
                SuccessToast("Success", "Call successfully initiated.");
            } else {
                ValidationErrorToast("Error", `Call could not be initiated: ${response.data?.error || 'Unknown error'}`);
            }
        } catch (error) {
            ValidationErrorToast("Error", `Call could not be initiated: ${error.message || 'Unknown error'}`);
        }
    };

    const handlePlayRecording = async (recordingUrl, interactionId) => {
        if (currentAudio) {
            currentAudio.pause();
            setIsPlaying(false);
        }

        try {
            const audio = new Audio(recordingUrl);
            setCurrentAudio(audio);
            
            audio.addEventListener('ended', () => {
                setIsPlaying(false);
            });

            audio.play();
            setIsPlaying(true);
        } catch (error) {
            ValidationErrorToast("Error", "Failed to play recording");
        }
    };

    const handlePauseCollection = async () => {
        try {
            await api.post(`accounts-receivable/customers/${organizationId}/pause`, {});
            SuccessToast("Success", "Collection paused for this customer");
            fetchCustomerData(); // Refresh data
        } catch (error) {
            ValidationErrorToast("Error", "Failed to pause collection");
        }
    };

    const handleResumeCollection = async () => {
        try {
            await api.post(`accounts-receivable/customers/${organizationId}/resume`, {});
            SuccessToast("Success", "Collection resumed for this customer");
            fetchCustomerData(); // Refresh data
        } catch (error) {
            ValidationErrorToast("Error", "Failed to resume collection");
        }
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'active':
                return '#28a745';
            case 'paused':
                return '#ffc107';
            case 'completed':
                return '#17a2b8';
            default:
                return '#dc3545';
        }
    };

    const getOutcomeColor = (outcome) => {
        switch (outcome) {
            case 'positive':
                return '#28a745';
            case 'neutral':
                return '#ffc107';
            case 'negative':
                return '#dc3545';
            default:
                return '#6c757d';
        }
    };

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Customer Profile</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Full Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} />
                        </Link>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.chat) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </span>
                        </li>

                        {user?.user?.type === STRING_CONSTANTS.userType.employer && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/modules">
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                            </Link>
                        </li>}
                    </div>
                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>
                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            {/* Back Button */}
                            <div style={{marginBottom: '24px', marginTop: '12px'}}>
                                <button 
                                    style={styles.backButton}
                                    onClick={() => props.history.push('/ar')}
                                >
                                    <span style={{fontSize: '18px', lineHeight: '1'}}>←</span> 
                                    <span>Back</span>
                                </button>
                            </div>

                            {/* Header Section */}
                            <div className='title-row d-flex justify-content-between align-items-center'>
                                <div>
                                    <h1 style={styles.whiteText}>{customer.name}</h1>
                                    <p style={styles.mutedText}>{customer.phone||"No phone number available"}</p>
                                </div>
                                <div style={{display: 'flex', gap: '10px'}}>
                                    {stats.remainingAmount > 0 && (
                                        <button 
                                            style={{...styles.button, ...styles.buttonPrimary}}
                                            onClick={() => initiateCall(customer.name)}
                                        >
                                            Call Now
                                        </button>
                                    )}
                                    <button 
                                        style={{
                                            ...styles.button,
                                            ...(customer.status === 'active' ? styles.buttonDanger : styles.buttonSuccess)
                                        }}
                                        onClick={customer.status === 'active' ? handlePauseCollection : handleResumeCollection}
                                    >
                                        {customer.status === 'active' ? 'Pause Collection' : 'Resume Collection'}
                                    </button>
                                </div>
                            </div>

                            <div className="scroll-bar-dash-full margn-top38">
                                <div className="row">
                                    {/* Stats Cards */}
                                    <div className="col-xl-12">
                                        <div className="row mb-4">
                                        {[
                                            {
                                                label: "Outstanding",
                                                value: `$${stats.remainingAmount.toLocaleString()}`,
                                                // icon: "💰",
                                                color: "#dc3545"
                                            },
                                            {
                                                label: "Collected",
                                                value: `$${stats.totalCollected.toLocaleString()}`,
                                                // icon: "✅",
                                                color: "#28a745"
                                            },
                                            {
                                                label: "Calls",
                                                value: `${stats.totalCalls}`,
                                                // icon: "📞",
                                                color: "#17a2b8"
                                            },
                                        ].map((stat, index) => (
                                            <div key={index} className="col-xl-3 col-md-6">
                                                <div style={styles.blueBox}>
                                                    <div style={styles.statsCard}>
                                                        <div style={styles.statsContent}>
                                                            <h4 style={styles.mutedText}>{stat.label}</h4>
                                                            <h2 style={styles.whiteText}>{stat.value}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>

                                        {/* Outstanding Invoices Section */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                Outstanding Invoices
                                            </h3>
                                            <div className="table-responsive">
                                                <table style={styles.table}>
                                                    <thead>
                                                        <tr>
                                                            <th style={styles.tableHeader}>Invoice #</th>
                                                            <th style={styles.tableHeader}>Amount Due</th>
                                                            <th style={styles.tableHeader}>Due Date</th>
                                                            <th style={styles.tableHeader}>Days Overdue</th>
                                                            <th style={styles.tableHeader}>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {customer.invoices?.map((invoice) => (
                                                            <tr key={invoice.InvoiceID}>
                                                                <td style={styles.tableCell}>{invoice.InvoiceNumber}</td>
                                                                <td style={styles.tableCell}>${invoice.AmountDue.toLocaleString()}</td>
                                                                <td style={styles.tableCell}>
                                                                    {moment(invoice.DueDate).format('DD/MM/YYYY')}
                                                                </td>
                                                                <td style={styles.tableCell}>
                                                                    <span style={{
                                                                        ...styles.badge,
                                                                        backgroundColor: invoice.overdue_flags.days_overdue > 90 ? '#dc3545' :
                                                                                    invoice.overdue_flags.days_overdue > 30 ? '#ffc107' :
                                                                                    invoice.overdue_flags.days_overdue > 0 ? '#17a2b8' : 
                                                                                    '#28a745'
                                                                    }}>
                                                                        {invoice.overdue_flags.days_overdue > 0 ? 
                                                                            `${invoice.overdue_flags.days_overdue} days` : 
                                                                            'Current'}
                                                                    </span>
                                                                </td>
                                                                <td style={styles.tableCell}>
                                                                    <button 
                                                                        style={{...styles.button, ...styles.buttonPrimary}}
                                                                        onClick={() => initiateCall(customer.name, invoice.InvoiceID)}
                                                                    >
                                                                        Call Now
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Payment Plan Info */}
                                        {false && customer.paymentPlan?.active && (
                                            <div style={styles.blueBox} className="mb-4">
                                                <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                    <img 
                                                        src={UtilityFunctions.getImageURL() + "calendar-icon-green.svg"}
                                                        style={{marginRight: '10px'}}
                                                        alt=""
                                                    />
                                                    Active Payment Plan
                                                </h3>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Start Date</p>
                                                        <p style={styles.whiteText}>
                                                            {moment(customer.paymentPlan.startDate).format('DD/MM/YYYY')}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Installment Amount</p>
                                                        <p style={styles.whiteText}>
                                                            ${customer.paymentPlan.installmentAmount.toLocaleString()}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Frequency</p>
                                                        <p style={styles.whiteText}>{customer.paymentPlan.frequency}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Next Payment</p>
                                                        <p style={styles.whiteText}>
                                                            {moment(customer.paymentPlan.nextPayment).format('DD/MM/YYYY')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Interaction Timeline */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img 
                                                    src={UtilityFunctions.getImageURL() + "history-icon-green.svg"}
                                                    style={{marginRight: '10px'}}
                                                    alt=""
                                                />
                                                Interaction History
                                            </h3>
                                            <div style={styles.timeline}>
                                                {interactions.map((interaction, index) => (
                                                    <div key={interaction.id} style={styles.timelineItem}>
                                                        <div style={{
                                                            ...styles.timelineDot,
                                                            backgroundColor: interaction.type === 'call' 
                                                                ? getOutcomeColor(interaction.outcome)
                                                                : '#28a745'
                                                        }} />
                                                        
                                                        <div style={{marginBottom: '15px'}}>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'flex-start',
                                                                marginBottom: '10px'
                                                            }}>
                                                                <div>
                                                                    <h4 style={styles.whiteText}>
                                                                        {interaction.type === 'call' ? (
                                                                            <>
                                                                                {interaction.direction === 'outbound' ? '📤' : '📥'} 
                                                                                {interaction.direction === 'outbound' ? 'Outbound Call' : 'Inbound Call'}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                💵 Payment Received
                                                                            </>
                                                                        )}
                                                                    </h4>
                                                                    <p style={styles.mutedText}>
                                                                        {moment(interaction.timestamp).format('DD/MM/YYYY HH:mm')}
                                                                    </p>
                                                                </div>
                                                                {interaction.type === 'call' && (
                                                                    <span style={{
                                                                        ...styles.badge,
                                                                        backgroundColor: getOutcomeColor(interaction.outcome)
                                                                    }}>
                                                                        {interaction.outcome.charAt(0).toUpperCase() + 
                                                                         interaction.outcome.slice(1)} Outcome
                                                                    </span>
                                                                )}
                                                            </div>

                                                            {interaction.type === 'call' ? (
                                                                <>
                                                                    <p style={styles.whiteText}>
                                                                        Duration: {formatDuration(interaction.duration)}
                                                                    </p>
                                                                    <p style={styles.mutedText}>
                                                                        {interaction.summary}
                                                                    </p>
                                                                    
                                                                    {/* Audio Player */}
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: '10px',
                                                                        marginTop: '10px'
                                                                    }}>
                                                                        <button
                                                                            style={{
                                                                                ...styles.button,
                                                                                ...styles.buttonPrimary,
                                                                                padding: '6px 12px'
                                                                            }}
                                                                            onClick={() => handlePlayRecording(
                                                                                interaction.recordingUrl,
                                                                                interaction.id
                                                                            )}
                                                                        >
                                                                            {isPlaying && currentAudio === interaction.id ? '⏸️' : '▶️'}
                                                                            {isPlaying && currentAudio === interaction.id ? 'Pause Recording' : 'Play Recording'}

                                                                        </button>
                                                                        <button
                                                                            style={{
                                                                                ...styles.button,
                                                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                                                padding: '6px 12px'
                                                                            }}
                                                                            onClick={() => setActiveTranscript(
                                                                                activeTranscript === interaction.id 
                                                                                    ? null 
                                                                                    : interaction.id
                                                                            )}
                                                                        >
                                                                            📄 {activeTranscript === interaction.id ? 'Hide Transcript' : 'View Transcript'}
                                                                        </button>
                                                                    </div>

                                                                    {/* Transcript */}
                                                                    {activeTranscript === interaction.id && (
                                                                        <div style={styles.transcriptBox}>
                                                                            {interaction.transcript}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p style={styles.whiteText}>
                                                                        Amount: ${interaction.amount.toLocaleString()}
                                                                    </p>
                                                                    <p style={styles.mutedText}>
                                                                        Method: {interaction.method}
                                                                    </p>
                                                                    <p style={styles.mutedText}>
                                                                        Reference: {interaction.reference}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withRouter(CustomerProfile);

import React, { useEffect, useState } from 'react';
import "./verified.css"
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { api } from "../../../middleware/api";

const Verified = (props) => {
    const [loading, setLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            api.get('subscription/', {})
                .then(res => {
                    setLoading(false);
                    if (res?.active) {
                        setIsSubscribed(true)
                    }
                }).catch((err) => {
                    setLoading(false);
                    console.log(err)
                })
        }, 5000)

    }, [])
    const redirect = () => {
        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" });
    }

    const redirectToProfile = () => {
        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" });
    }

    return (
        <div className='container-fluid position-relative p-0 d-flex justify-content-center verified_main_container'>

            <div className='logo-position'>
                <img src="https://mploi.com/assets/images/logo_White.svg" />
            </div>

            <div className='container-sm  mt-5 d-flex justify-content-center '>
                <div className='d-flex'>
                    {/* <div className='' style={{ cursor: 'pointer' }} onClick={redirect}>
                        <img src="/assets/images/dismiss.svg" alt="" />
                    </div> */}
                    <div className="data-container">
                        {
                            !loading ? <>
                                {isSubscribed
                                    ?
                                    <>
                                        <div>
                                            <p className='v_cong_txt'> Congratulations</p>
                                        </div>
                                        <div className="v_cong_container mx-auto mt-4 ">
                                            <p className="v_subs_txt">Your subscription has been successfully activated.</p>
                                        </div>
                                    </>
                                    : <>
                                        <div>
                                            <p className='v_cong_txt'>Oops Verification Failed!!</p>
                                        </div>
                                        <div className="v_cong_container mx-auto mt-4 ">
                                            <p className="v_subs_txt">We could not verify your subscription. Please refresh after some time or contact support.</p>
                                        </div>
                                    </>
                                }

                            </> :
                                <>
                                    <div>
                                        <p className='v_cong_txt'> Verifying Subscription...</p>
                                    </div>
                                    <div className="v_cong_container mx-auto mt-4 ">
                                        <p className="v_subs_txt">Your subscription is being verified...</p>
                                    </div>
                                </>
                        }


                        <div className=' design-img-container mr-tp  d-flex justify-content-center '>
                            <img className='mx-auto  ' src="/assets/images/verified_design.png" />
                        </div>

                        {
                            isSubscribed ?
                                <><div className='center my-1'>
                                    <div className='v_plan_btn ' style={{ cursor: 'pointer' }} onClick={redirect}>
                                        <p className='m-0 v_plan_txt'>Return to Profile</p>
                                    </div>
                                </div>

                                    {/* <div className='container mx-auto mt-5'>
                                        <p className='verify_btn_txt' style={{ cursor: 'pointer' }} onClick={redirectToProfile}>Return to app to customise profile</p>
                                    </div> */}
                                </>
                                :
                                !loading ?
                                    <div className='center my-1'>
                                        <div className='v_plan_btn ' style={{ cursor: 'pointer' }} onClick={redirect}>
                                            <p className='m-0 v_plan_txt'>Return to Profile</p>
                                        </div>
                                    </div>
                                    :
                                    ""
                        }



                    </div>

                </div>
            </div>
        </div>
    )
}

export default Verified;

